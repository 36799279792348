import {
  APIEndpointParameter,
  KeyPath,
  RequestLocation,
} from "../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_APIEndpointParameter";
import {
  SelectiveSyncFilterSchema,
  UserFacingFilterDetails,
  VersionedComponentInfo,
} from "../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { SelectiveSyncFilterSchema as NativeSelectiveSyncFilterSchema } from "../../../autogenerated-types/AUTOGENERATED_NativePydantic_SelectiveSyncFilterSchema";
import { APIEndpointParameter as NativeAPIEndpointParameter } from "../../../autogenerated-types/AUTOGENERATED_NativePydantic_APIEndpointParameter";
import {
  ComponentState,
  VersionedComponentAPIComponentKeys,
} from "../../versioned-components/types";
import { ConditionType } from "./types";

const KEYS_TO_IGNORE = ["created_at", "modified_at"];

export const convertExpandedPydanticModelToNativePydanticModel = (
  inputModel: SelectiveSyncFilterSchema | APIEndpointParameter,
  componentClass: VersionedComponentAPIComponentKeys
): any => {
  // SelectiveSyncFilterSchema case
  if (componentClass === VersionedComponentAPIComponentKeys.SelectiveSyncFilterSchema) {
    const filterSchema = { ...(inputModel as SelectiveSyncFilterSchema) };

    const filterTypeId = filterSchema.user_facing_filter_details?.filter_type_id?.id ?? "";

    const parameterMappingDetails = filterSchema.filter_mapping_details ?? {};

    const transformedParameterMappingDetails = Object.entries(parameterMappingDetails).reduce(
      (acc, [key, details]) => {
        acc[key] = {
          ...details,
          api_endpoint_filter_mappings: details.api_endpoint_filter_mappings.map((mapping) => ({
            ...mapping,
            api_endpoint_filter_id:
              mapping?.api_endpoint_filter_id?.published_version?.id ||
              mapping?.api_endpoint_filter_id?.next_version?.id ||
              null,
          })),
        };
        return acc;
      },
      {} as Record<string, any>
    );

    const transformedModel: NativeSelectiveSyncFilterSchema = {
      ...filterSchema,
      user_facing_filter_details: {
        ...(filterSchema.user_facing_filter_details as UserFacingFilterDetails),
        filter_type_id: filterTypeId, // Replace the object with just the ID
      },
      filter_mapping_details: transformedParameterMappingDetails, // add in the transformed filter mapping details which only contain the api endpoint ids
    };

    // Use reduce to selectively include properties, ignoring the KEYS_TO_IGNORE
    const finalModel = Object.entries(transformedModel).reduce((acc, [key, value]) => {
      if (!KEYS_TO_IGNORE.includes(key)) {
        acc[key as keyof SelectiveSyncFilterSchema] =
          transformedModel[key as keyof SelectiveSyncFilterSchema] ?? value;
      }
      return acc;
    }, {} as typeof filterSchema);

    return finalModel;
  }

  // APIEndpointParameter case
  if (componentClass === VersionedComponentAPIComponentKeys.APIEndpointParameter) {
    const apiEndpointParameter = { ...(inputModel as APIEndpointParameter) };
    const apiEndpointID = apiEndpointParameter?.api_endpoint_id?.id ?? "";

    const transformedModel: NativeAPIEndpointParameter = {
      ...apiEndpointParameter,
      request_location: apiEndpointParameter.request_location as RequestLocation,
      key_path: apiEndpointParameter.key_path as KeyPath,
      api_endpoint_id: apiEndpointID,
    };

    // Use reduce to selectively include properties, ignoring the KEYS_TO_IGNORE
    const finalModel = Object.entries(transformedModel).reduce((acc, [key, value]) => {
      if (!KEYS_TO_IGNORE.includes(key)) {
        acc[key as keyof APIEndpointParameter] =
          transformedModel[key as keyof APIEndpointParameter] ?? value;
      }
      return acc;
    }, {} as typeof apiEndpointParameter);

    return finalModel;
  }
};

export const shallowEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const getAPIEndpointParametersFromSelectiveSyncFilterSchema = (
  schema: SelectiveSyncFilterSchema
): (VersionedComponentInfo | undefined)[] => {
  const result: (VersionedComponentInfo | undefined)[] = [];

  if (!schema) return result;

  // Extract from filter_mapping_details
  const filterMappingDetails = schema.filter_mapping_details;
  if (filterMappingDetails) {
    Object.values(filterMappingDetails).forEach((mappingDetails) => {
      const apiEndpointFilterMappings = mappingDetails.api_endpoint_filter_mappings;
      apiEndpointFilterMappings.forEach((mapping) => {
        if (mapping.api_endpoint_filter_id) {
          // Fallback to "published_version" if "next_version" doesn't exist
          const expandedAPIEndpointParameter = mapping.api_endpoint_filter_id?.next_version
            ? mapping.api_endpoint_filter_id?.next_version
            : mapping.api_endpoint_filter_id?.published_version;
          const nativeAPIEndpointParameter = convertExpandedPydanticModelToNativePydanticModel(
            expandedAPIEndpointParameter as APIEndpointParameter,
            VersionedComponentAPIComponentKeys.APIEndpointParameter
          );
          result.push(nativeAPIEndpointParameter);
        }
      });
    });
  }

  return result;
};

// Check if our component state is staged
export const isStagedComponent = (componentState: ComponentState) =>
  [
    ComponentState.PUBLISHED_WITH_NEW_STAGED_VERSION,
    ComponentState.NEW_COMPONENT_WITH_STAGED_VERSION,
  ].includes(componentState);

// Check if our component state is published
export const isPublishedComponent = (componentState: ComponentState) =>
  [
    ComponentState.PUBLISHED_WITH_NEW_STAGED_VERSION,
    ComponentState.PUBLISHED,
    ComponentState.PUBLISHED_WITH_NEW_DRAFT,
  ].includes(componentState);

export const getHumanReadableConditionType = (value: ConditionType): string => {
  return value
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("");
};
