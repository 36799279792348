import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import clsx from "clsx";
import { CircleCheck, OctagonX, TriangleAlert } from "lucide-react";
import { useHistory } from "react-router-dom";
import {
  navigateToBlueprintEditor,
  navigateToIntegrationBuilderSelectiveSyncFilterBuilder,
  navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId,
  navigateToTestSuitesTable,
} from "../../../../router/RouterUtils";
import { useStagedComponentContext } from "../context/StagedComponentContext";
import {
  IntegrationComponentType,
  IntegrationValidatorRuleResult,
  IntegrationValidatorRuleResultType,
  IntegrationValidatorRuleSeverityType,
  ValidatedStagedComponent,
} from "../types";

interface Props {
  validatorRuleResult: IntegrationValidatorRuleResult;
  setIsModalOpen: (value: boolean) => void;
  className?: string;
}

interface ValidatorRuleActionProps {
  title: string;
  onClick: () => void;
}

interface ValidatoRuleAlertProps {
  icon: JSX.Element;
  title: string;
}

const getAlertProps = (
  validatorRuleResult: IntegrationValidatorRuleResult
): ValidatoRuleAlertProps => {
  const { severity, result, is_overridden } = validatorRuleResult;

  // If the result is overridden, we want to show a gray alert with a checkmark or x depending on if the overridden alert passed
  if (is_overridden) {
    return {
      icon:
        result === IntegrationValidatorRuleResultType.SUCCESS ? (
          <CircleCheck className="text-teal-70" size={16} />
        ) : (
          <OctagonX className="text-red-50" size={16} />
        ),
      title:
        result === IntegrationValidatorRuleResultType.SUCCESS
          ? validatorRuleResult.rule_name
          : validatorRuleResult.exception_message || validatorRuleResult.rule_name,
    };
  }

  // If the result is not overridden and a success, we want to show a green alert with a checkmark
  if (result === IntegrationValidatorRuleResultType.SUCCESS) {
    return {
      icon: <CircleCheck className="text-black" size={16} />,
      title: validatorRuleResult.rule_name,
    };
  }

  // All other alerts should be displayed by their severity
  switch (severity) {
    case IntegrationValidatorRuleSeverityType.BLOCKING:
      return {
        icon: <OctagonX className="text-black" size={16} />,
        title: validatorRuleResult.exception_message || validatorRuleResult.rule_name,
      };
    case IntegrationValidatorRuleSeverityType.WARNING:
      return {
        icon: <TriangleAlert className="text-black" size={16} />,
        title: validatorRuleResult.exception_message || validatorRuleResult.rule_name,
      };
    default:
      return {
        icon: <CircleCheck className="text-black" size={16} />,
        title: validatorRuleResult.exception_message || validatorRuleResult.rule_name,
      };
  }
};

const getActionButtonProps = (
  component: ValidatedStagedComponent,
  ruleResult: IntegrationValidatorRuleResult,
  integrationID: string,
  history: any
): ValidatorRuleActionProps | null => {
  const defaultActionButtonProps: { [id: string]: ValidatorRuleActionProps } = {
    [IntegrationComponentType.BLUEPRINT]: {
      title: "Go to blueprint",
      onClick: () =>
        navigateToBlueprintEditor(history, integrationID, component.component_version_id, true),
    },
    [IntegrationComponentType.API_ENDPOINT_PARAMETER]: {
      title: "Go to filter",
      onClick: () =>
        navigateToIntegrationBuilderSelectiveSyncFilterBuilder(history, integrationID, true),
    },
    [IntegrationComponentType.SELECTIVE_SYNC_FILTER_SCHEMA]: {
      title: "Go to filter",
      onClick: () =>
        navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId(
          history,
          integrationID,
          true,
          ruleResult.component_version_id
        ),
    },
  };

  const ruleSpecificActionButtonProps: { [id: string]: ValidatorRuleActionProps } = {
    mapping_test_coverage: {
      title: "Go to mapping tests",
      onClick: () => {
        navigateToTestSuitesTable(history, integrationID, true);
      },
    },
  };

  if (ruleResult.rule_id in ruleSpecificActionButtonProps) {
    return ruleSpecificActionButtonProps[ruleResult.rule_id];
  }
  if (component.component_type in defaultActionButtonProps) {
    return defaultActionButtonProps[component.component_type];
  }
  return null;
};

const IntegrationValidatorRuleAlertTitle = ({
  validatorRuleResult,
  setIsModalOpen,
  className,
}: Props) => {
  const history = useHistory();
  const { component, integrationID, removeOverride } = useStagedComponentContext();
  const { icon, title } = getAlertProps(validatorRuleResult);
  const actionButtonProps = getActionButtonProps(
    component,
    validatorRuleResult,
    integrationID,
    history
  );

  const handleActionButton = (e: any) => {
    e.stopPropagation();
    actionButtonProps?.onClick();
  };

  const handleOverride = (e: any) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const handleRemoveOverride = (e: any) => {
    e.stopPropagation();
    removeOverride(validatorRuleResult);
  };

  const canOverride =
    validatorRuleResult.severity === IntegrationValidatorRuleSeverityType.BLOCKING &&
    validatorRuleResult.result === IntegrationValidatorRuleResultType.FAILURE;
  const canRemoveOverride = validatorRuleResult.is_overridden;
  const hasActionButton = validatorRuleResult.result !== IntegrationValidatorRuleResultType.SUCCESS;

  return (
    <div
      className={clsx(
        className,
        "bg-transparent flex flex-row items-center w-full space-x-5 rounded-lg mr-3"
      )}
    >
      {icon}
      <div className="flex flex-row items-center justify-between w-full">
        <Text className="text-black">{title}</Text>
        <div className="flex flex-row items-center space-x-3">
          {canRemoveOverride ? (
            <Button onClick={handleRemoveOverride} size="sm" variant={ButtonVariant.TextBlack}>
              Turn on check
            </Button>
          ) : canOverride ? (
            <Button onClick={handleOverride} size="sm" variant={ButtonVariant.TextBlack}>
              Override
            </Button>
          ) : null}
          {hasActionButton && actionButtonProps && (
            <Button onClick={handleActionButton} size="sm" variant={ButtonVariant.TertiaryWhite}>
              {actionButtonProps.title}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default IntegrationValidatorRuleAlertTitle;
