import AccordionCard from "../../../shared/AccordionCard";
import KeyPathFieldHeader from "../../../shared/KeyPathFieldHeader";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import BinaryChoiceButtons from "../shared/BinaryChoiceButtons";
import LineIndent from "../shared/LineIndent";

interface RefreshURLAdvancedProps {
  shouldUseTokenURLForRefresh: boolean | undefined;
  setShouldUseTokenURLForRefresh: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  doesRefreshIncludeExpiresIn: boolean | undefined;
  setDoesRefreshIncludeExpiresIn: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  oAuthRefreshURL: string | undefined;
  setOAuthRefreshURL: React.Dispatch<React.SetStateAction<string | undefined>>;
  oAuthRefreshTokenLifespanInSeconds: number | undefined;
  setOAuthRefreshTokenLifespanInSeconds: React.Dispatch<React.SetStateAction<number | undefined>>;
  refreshURLFieldsToExclude: string[] | null;
  setRefreshURLFieldsToExclude: React.Dispatch<React.SetStateAction<string[] | null>>;
}

const RefreshURLAdvanced = ({
  shouldUseTokenURLForRefresh,
  setShouldUseTokenURLForRefresh,
  doesRefreshIncludeExpiresIn,
  setDoesRefreshIncludeExpiresIn,
  oAuthRefreshURL,
  setOAuthRefreshURL,
  oAuthRefreshTokenLifespanInSeconds,
  setOAuthRefreshTokenLifespanInSeconds,
  refreshURLFieldsToExclude,
  setRefreshURLFieldsToExclude,
}: RefreshURLAdvancedProps) => {
  let badgeNumber: number;

  if (doesRefreshIncludeExpiresIn && !shouldUseTokenURLForRefresh) {
    badgeNumber = 3;
  } else if (!doesRefreshIncludeExpiresIn && !shouldUseTokenURLForRefresh) {
    badgeNumber = 4;
  } else if (!doesRefreshIncludeExpiresIn && shouldUseTokenURLForRefresh) {
    badgeNumber = 5;
  } else {
    badgeNumber = 0; // Default or error value, adjust as needed
  }
  return (
    <AccordionCard
      dataTestID="accordion-auth-config-advanced-refresh-url-configuration"
      title="Advanced"
      badgeContents={badgeNumber}
      variant="outline"
      className="mt-6 mb-0"
      nested
    >
      <>
        <BinaryChoiceButtons
          dataTestID="field-auth-config-refresh-url-includes-expires-in"
          title="Does the refresh URL response include an `expires_in` parameter?"
          binaryChoice={doesRefreshIncludeExpiresIn}
          setBinaryChoice={setDoesRefreshIncludeExpiresIn}
        />
        {doesRefreshIncludeExpiresIn === false && (
          <LineIndent className="mt-6">
            <TextFieldHeader
              dataTestID="field-auth-config-refresh-token-lifespan"
              title="Refresh token lifespan"
              subtitle="Refresh token lifespan in seconds"
              placeholder="12345"
              value={oAuthRefreshTokenLifespanInSeconds}
              type="number"
              onChange={(newEvent) => {
                const value = newEvent.target.value;
                if (value === "") {
                  setOAuthRefreshTokenLifespanInSeconds(undefined);
                } else {
                  const numericValue = parseInt(value, 10);
                  if (!isNaN(numericValue)) {
                    setOAuthRefreshTokenLifespanInSeconds(numericValue);
                  }
                }
              }}
            />
          </LineIndent>
        )}
        <BinaryChoiceButtons
          dataTestID="field-auth-config-refresh-url-different-from-token-url"
          className="mt-6"
          title="Is the refresh URL different from the token URL?"
          binaryChoice={shouldUseTokenURLForRefresh}
          setBinaryChoice={setShouldUseTokenURLForRefresh}
        />
      </>
      {shouldUseTokenURLForRefresh === true && (
        <LineIndent className="mt-6">
          <TextFieldHeader
            dataTestID="field-auth-config-refresh-url"
            title="Refresh URL"
            subtitle="Enter the URL required to refresh the access token. If this is left blank, per basic OAuth standards, the OAuth token URL will be re-used."
            placeholder="example.com"
            prefix="https://"
            prefixVariant="url"
            value={oAuthRefreshURL}
            onChange={(newEvent) => {
              setOAuthRefreshURL(newEvent.target.value);
            }}
          />
        </LineIndent>
      )}
      <KeyPathFieldHeader
        dataTestID="field-auth-config-refresh-url-fields-to-exclude"
        className="mt-6"
        title="Refresh URL fields to exclude"
        subtitle="In very rare cases, an integration may need to exclude fields from the body of just token refresh requests. Type the name of the field below and press Enter."
        placeholder="Add key name..."
        keyPath={refreshURLFieldsToExclude}
        onKeyPathChange={setRefreshURLFieldsToExclude}
        hasSource={false}
        showArrow={false}
      />
    </AccordionCard>
  );
};

export default RefreshURLAdvanced;
