import { Badge, Text, Tooltip } from "@merge-api/merge-javascript-shared";
import {
  LinkedAccount,
  mapDisplayableLinkedAccountStatus,
  mapLinkedAccountStatusToBadgeColor,
} from "../../../models/Entities";

interface Props {
  option: LinkedAccount;
}

const LinkedAccountTypeaheadRenderOption = ({ option }: Props) => {
  return (
    <div className="flex flex-row items-center justify-between w-full">
      <div className="flex flex-col items-start w-2/3 truncate">
        <Text variant="sm" className="text-gray-70 w-full truncate">
          Created on {new Date(option.created_at).toLocaleDateString()}
        </Text>
        <Tooltip title={option.end_user.organization_name} className="w-full truncate">
          <Text className="w-full truncate">{option.end_user.organization_name}</Text>
        </Tooltip>
        <Text variant="sm" className="text-gray-70 w-full truncate">
          {option.id}
        </Text>
      </div>
      <div className="flex flex-row space-x-2">
        {option.status && (
          <Badge size="sm" color={mapLinkedAccountStatusToBadgeColor[option.status]}>
            {mapDisplayableLinkedAccountStatus[option.status]}
          </Badge>
        )}
        {option.is_sandbox_account && <Badge size="sm">Sandbox</Badge>}
      </div>
    </div>
  );
};

export default LinkedAccountTypeaheadRenderOption;
