import { Text } from "@merge-api/merge-javascript-shared";
import { JSONObjectSchema } from "../../../models/Blueprints";
import ParameterSchemaEditor from "./web-connector/ParameterSchemaEditor";
import QBXMLEditor from "./web-connector/QBXMLEditor";

interface BlueprintEditorLeftPanelConnectorSubtabProps {
  qbXMLQueryRequestFormat: undefined | string;
  parameterSchema: undefined | JSONObjectSchema;
}

// This tab is specifically used to modify fields on a blueprint that are relevant
// to the QuickBooks Desktop integration. It allows the user to edit the qbXML
// query request format and the parameter schema used for the web connector
// settings, ensuring the blueprint can be configured appropriately for QuickBooks
// Desktop sync operations.

const BlueprintEditorLeftPanelConnectorSubtab = ({
  qbXMLQueryRequestFormat,
  parameterSchema,
}: BlueprintEditorLeftPanelConnectorSubtabProps) => {
  return (
    <div>
      <Text as="div" variant="h5" className="mb-4">
        Web connector settings
      </Text>
      <QBXMLEditor qbXMLQueryRequestFormat={qbXMLQueryRequestFormat} />
      <ParameterSchemaEditor parameterSchema={parameterSchema} />
    </div>
  );
};

export default BlueprintEditorLeftPanelConnectorSubtab;
