import AccordionCard from "../../../shared/AccordionCard";
import KeyPathFieldHeader from "../../../shared/KeyPathFieldHeader";

interface TokenURLAdvancedProps {
  oAuthTokenURLHeaderKeysToExclude: string[] | null;
  setOAuthTokenURLHeaderKeysToExclude: React.Dispatch<React.SetStateAction<string[] | null>>;
  tokenURLRequestParams: string[] | null;
  setTokenURLRequestParams: React.Dispatch<React.SetStateAction<string[] | null>>;
  oAuthResponseTokenKeyPath: string[] | null;
  setOAuthResponseTokenKeyPath: React.Dispatch<React.SetStateAction<string[] | null>>;
}

const TokenURLAdvanced = ({
  oAuthTokenURLHeaderKeysToExclude,
  setOAuthTokenURLHeaderKeysToExclude,
  tokenURLRequestParams,
  setTokenURLRequestParams,
  oAuthResponseTokenKeyPath,
  setOAuthResponseTokenKeyPath,
}: TokenURLAdvancedProps) => {
  return (
    <AccordionCard
      dataTestID="accordion-auth-config-advanced-token-configuration"
      title="Advanced"
      badgeContents={3}
      variant="outline"
      className="mt-6 mb-0"
      nested
    >
      <KeyPathFieldHeader
        dataTestID="field-auth-config-token-url-header-keys-to-exclude"
        title="Token URL header keys to exclude"
        subtitle="In very rare cases, an integration may need to exclude header parameters for authorization, but still keep the parameters for subsequent API requests. Type the name of the key below and press Enter."
        placeholder="Add key name..."
        keyPath={oAuthTokenURLHeaderKeysToExclude}
        onKeyPathChange={setOAuthTokenURLHeaderKeysToExclude}
        hasSource={false}
        showArrow={false}
      />
      <KeyPathFieldHeader
        dataTestID="field-auth-config-token-url-params-to-exclude"
        className="mt-6"
        title="Params to exclude"
        subtitle="Select the params to exclude from the body of both the initial token fetch or token refresh requests. Type the name of the key below and press Enter."
        learnMoreText="For example, the Deel integration excludes 'client_secret' and 'client_assertion'."
        placeholder="Add key name..."
        keyPath={tokenURLRequestParams}
        onKeyPathChange={setTokenURLRequestParams}
        hasSource={false}
        showArrow={false}
      />
      <KeyPathFieldHeader
        dataTestID="field-auth-config-token-url-response-token-key-path-override"
        className="mt-6"
        title="Response token key path override"
        subtitle="Enter the keys creating a path to the token in the response after a token exchange. Type the name of the key below and press Enter."
        placeholder="Add key name..."
        learnMoreText="If the token follows standard OAuth protocol and comes back as `access_token`, leave this blank. However, some integrations have nested paths, like Personio, where the path is 'data' > 'token'. To enter this, you would type 'data', press Enter, and type `token` and press Enter."
        keyPath={oAuthResponseTokenKeyPath}
        onKeyPathChange={setOAuthResponseTokenKeyPath}
        hasSource={false}
      />
    </AccordionCard>
  );
};

export default TokenURLAdvanced;
