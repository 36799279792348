import {
  Blueprint,
  BlueprintOperationType,
  DISABLE_FILTER_BY_DATE_OPERATION_TYPES,
} from "../../../../models/Blueprints";
import BlueprintMaxLoopIterationsPerStepCollapsableSection from "./BlueprintMaxLoopIterationsPerStepCollapsableSection";
import LeftPanelTextField from "../shared/LeftPanelTextField";
import LeftPanelCheckbox from "../shared/LeftPanelCheckbox";
import LeftPanelAccordion from "../shared/LeftPanelAccordion";
import { AbstractCondition } from "../../../integration-builder/selective-sync-filters/types";
import BlueprintLeftPanelConsoleSelectiveSync from "./abstract-conditions-for-selective-sync/BlueprintLeftPanelSelectiveSync";
import { AbstractConditionAction } from "../../reducers/AbstractConditionsForSelectiveSyncReducer";

interface Props {
  blueprint: Blueprint;
  maxLoopIterations: number | undefined;
  setMaxLoopIterations: (x: number) => void;
  maxLoopIterationsPerStep: { [stepID: string]: number };
  setMaxLoopIterationsPerStep: (x: { [stepID: string]: number }) => void;
  maxPageIterations: number | undefined;
  setMaxPageIterations: (x: number) => void;
  frozenTimeValue: string | undefined;
  handleFrozenTimeInputChange: (value: string) => void;
  overrideLastRunAtValue: string | undefined;
  handleOverrideLastRunAtChange: (value: string) => void;
  isFrozenTimeError: boolean;
  frozenTimeErrorText: string | undefined;
  isOverrideLastRunAtError: boolean;
  overrideLastRunAtErrorText: string | undefined;
  disableFilterByDate: boolean;
  setDisableFilterByDate: (x: boolean) => void;
  disableSyncCursor: boolean;
  setDisableSyncCursor: (x: boolean) => void;
  shouldFilterDisabledFields: boolean;
  setShouldFilterDisabledFields: (x: boolean) => void;
  shouldUseLastScraperResults: boolean;
  setShouldUseLastScraperResults: (x: boolean) => void;
  shouldRunAsAsyncTask: boolean;
  setShouldRunAsAsyncTask: (x: boolean) => void;
  shouldIncludeNestedParams: boolean;
  setShouldIncludeNestedParams: (x: boolean) => void;
  shouldGenerateMappingTest: boolean;
  setShouldGenerateMappingTest: (x: boolean) => void;
  canAutogenerateMappingTestForBlueprint: boolean;
  fetchRecentTestRuns: () => void;
  selectedTestLinkedAccount: undefined | string;
  shouldLogStepIO: boolean;
  setShouldLogStepIO: (x: boolean) => void;
  abstractConditionsForSelectiveSync: AbstractCondition[];
  dispatchAbstractConditionsForSelectiveSync: React.Dispatch<AbstractConditionAction>;
}

/**
 * Renders the "Settings" accordion in Blueprint Editor left panel console
 * Defines the fields for configuring a test run, such as max loop iterations
 * For certain Blueprint types like FETCH, we want to render a different set of settings
 */
const BlueprintLeftPanelConsoleSettings = ({
  blueprint,
  maxLoopIterations,
  setMaxLoopIterations,
  maxLoopIterationsPerStep,
  setMaxLoopIterationsPerStep,
  maxPageIterations,
  setMaxPageIterations,
  frozenTimeValue,
  handleFrozenTimeInputChange,
  overrideLastRunAtValue,
  handleOverrideLastRunAtChange,
  isFrozenTimeError,
  frozenTimeErrorText,
  isOverrideLastRunAtError,
  overrideLastRunAtErrorText,
  disableFilterByDate,
  setDisableFilterByDate,
  disableSyncCursor,
  setDisableSyncCursor,
  shouldFilterDisabledFields,
  setShouldFilterDisabledFields,
  shouldUseLastScraperResults,
  setShouldUseLastScraperResults,
  shouldRunAsAsyncTask,
  setShouldRunAsAsyncTask,
  shouldIncludeNestedParams,
  setShouldIncludeNestedParams,
  canAutogenerateMappingTestForBlueprint,
  shouldGenerateMappingTest,
  setShouldGenerateMappingTest,
  shouldLogStepIO,
  setShouldLogStepIO,
  abstractConditionsForSelectiveSync,
  dispatchAbstractConditionsForSelectiveSync,
}: Props) => {
  return (
    <LeftPanelAccordion title="Settings" defaultExpanded={true}>
      {[
        BlueprintOperationType.FETCH,
        BlueprintOperationType.WEBHOOK_SETUP,
        BlueprintOperationType.ACCOUNT_DELETION,
        BlueprintOperationType.FETCH_FILTER_OPTIONS,
      ].includes(blueprint.operation_type) ? (
        <>
          <LeftPanelTextField
            type="number"
            title="Max loop iterations"
            subtitle="The default maximum number of items to loop over in any loop before breaking early"
            placeholder="Enter max # of loops for all loops"
            value={maxLoopIterations}
            onChange={(e) => setMaxLoopIterations(parseInt(e.target.value, 10))}
          />
          <BlueprintMaxLoopIterationsPerStepCollapsableSection
            defaultValue={maxLoopIterations}
            maxLoopIterationsPerStep={maxLoopIterationsPerStep}
            setMaxLoopIterationsPerStep={setMaxLoopIterationsPerStep}
          />
          <LeftPanelTextField
            type="number"
            title="Max page iterations"
            subtitle="The maximum number of pages to request in any APIRequestLoop step before not fetching any more pages"
            placeholder="Enter max page iterations value"
            value={maxPageIterations}
            onChange={(e) => setMaxPageIterations(parseInt(e.target.value, 10))}
          />
          <LeftPanelTextField
            type="text"
            title="Frozen time"
            subtitle="Specify a frozen time value to use when executing your blueprint. This will set a static time reference for any date range loops or custom functions. Use ISO 8601 format."
            placeholder="Example: 2021-01-01 00:00:00"
            value={frozenTimeValue}
            onChange={(e) => handleFrozenTimeInputChange(e.target.value)}
            error={isFrozenTimeError}
            errorText={frozenTimeErrorText}
          />
          <LeftPanelTextField
            type="text"
            title="Override last run at"
            subtitle="Specify the timestamp for which this blueprint was last modified at. This will set a static time reference for any modified since functions, custom functions, or date range loops (assuming pagination isn't in use). Use ISO 8601 format."
            placeholder="Example: 2021-01-01 00:00:00"
            value={overrideLastRunAtValue}
            onChange={(e) => handleOverrideLastRunAtChange(e.target.value)}
            error={isOverrideLastRunAtError}
            errorText={overrideLastRunAtErrorText}
          />
          {blueprint.operation_type === BlueprintOperationType.FETCH && (
            <BlueprintLeftPanelConsoleSelectiveSync
              integrationID={blueprint.integration.id}
              abstractConditionsForSelectiveSync={abstractConditionsForSelectiveSync}
              dispatchAbstractConditionsForSelectiveSync={
                dispatchAbstractConditionsForSelectiveSync
              }
            />
          )}
          {!DISABLE_FILTER_BY_DATE_OPERATION_TYPES.includes(blueprint.operation_type) && (
            <LeftPanelCheckbox
              label="Disable filter by date"
              checked={disableFilterByDate}
              onChange={() => setDisableFilterByDate(!disableFilterByDate)}
            />
          )}
          {!DISABLE_FILTER_BY_DATE_OPERATION_TYPES.includes(blueprint.operation_type) && (
            <LeftPanelCheckbox
              label="Disable sync cursor"
              checked={disableSyncCursor}
              onChange={() => setDisableSyncCursor(!disableSyncCursor)}
            />
          )}
          <LeftPanelCheckbox
            tooltipTitle="Enable the use of the test linked account and Merge organization's
                            disabled scopes during test run execution. By default, test
                            blueprint runs don't respect disabled scopes to enable ease of
                            testing for all mapped values."
            label="Filter disabled fields for org"
            checked={shouldFilterDisabledFields}
            onChange={() => setShouldFilterDisabledFields(!shouldFilterDisabledFields)}
          />
          {blueprint.scraper && (
            <LeftPanelCheckbox
              tooltipTitle="If enabled, the test payload will not be used. The last successful scraper execution data will be used instead."
              label="Use last scraper results"
              checked={shouldUseLastScraperResults}
              onChange={() => setShouldUseLastScraperResults(!shouldUseLastScraperResults)}
            />
          )}
          <LeftPanelCheckbox
            tooltipTitle="Blueprint runs that exceed 60s must be run as async tasks. Async tasks can run up to 10 minutes."
            label="Run as async task"
            checked={shouldRunAsAsyncTask}
            onChange={() => setShouldRunAsAsyncTask(!shouldRunAsAsyncTask)}
          />
        </>
      ) : (
        <>
          <LeftPanelCheckbox
            tooltipTitle="Checking this box will expand nested parameters in the default payload input, including both relational parameters and integration parameters"
            label="Expand nested parameters in payload"
            checked={shouldIncludeNestedParams}
            onChange={() => setShouldIncludeNestedParams(!shouldIncludeNestedParams)}
          />
          <LeftPanelCheckbox
            tooltipTitle="Mapping tests can only be generated for write blueprints that are staged, have no unsaved changes, and are running for a sandbox linked account. This is not a debugger. If there are any errors in the blueprint, the autogenerated test may still succeed!"
            label="Generate mapping test on run success"
            checked={shouldGenerateMappingTest}
            onChange={() => setShouldGenerateMappingTest(!shouldGenerateMappingTest)}
            disabled={!canAutogenerateMappingTestForBlueprint}
          />
        </>
      )}
      <LeftPanelCheckbox
        tooltipTitle="Enable to see inputs & outputs for each step, as well as changes in variable values"
        label="Enable step I/O logging"
        checked={shouldLogStepIO}
        onChange={() => setShouldLogStepIO(!shouldLogStepIO)}
      />
    </LeftPanelAccordion>
  );
};

export default BlueprintLeftPanelConsoleSettings;
