import { Button, ButtonVariant, Text } from "@merge-api/merge-javascript-shared";
import { AbstractCondition } from "../../../../integration-builder/selective-sync-filters/types";
import { Plus } from "lucide-react";
import BlueprintLeftPanelSelectiveSyncFilterCard from "./BlueprintLeftPanelSelectiveSyncFilterCard";
import useFetchUserFacingFilterTypes from "./hooks/useFetchUserFacingFilterTypeOptions";
import {
  ADD_CONDITION,
  AbstractConditionAction,
} from "../../../reducers/AbstractConditionsForSelectiveSyncReducer";

interface Props {
  integrationID: string;
  abstractConditionsForSelectiveSync: AbstractCondition[];
  dispatchAbstractConditionsForSelectiveSync: React.Dispatch<AbstractConditionAction>;
}

/**
 * Renders the Selective Sync filter settings in Blueprint Editor left panel console
 */
const BlueprintLeftPanelSelectiveSync = ({
  integrationID,
  abstractConditionsForSelectiveSync,
  dispatchAbstractConditionsForSelectiveSync,
}: Props) => {
  const { filterTypeOptions } = useFetchUserFacingFilterTypes({ integrationID });

  return (
    <div className="flex flex-col space-y-2 w-full">
      <Text variant="h6">Selective sync filters</Text>
      {(abstractConditionsForSelectiveSync ?? []).map((_, index) => {
        return (
          <BlueprintLeftPanelSelectiveSyncFilterCard
            index={index}
            abstractConditionsForSelectiveSync={abstractConditionsForSelectiveSync}
            dispatchAbstractConditionsForSelectiveSync={dispatchAbstractConditionsForSelectiveSync}
            filterTypeOptions={filterTypeOptions}
          />
        );
      })}
      <Button
        size="sm"
        variant={ButtonVariant.TertiaryWhite}
        leftIcon={<Plus size={12} />}
        onClick={() =>
          dispatchAbstractConditionsForSelectiveSync({
            type: ADD_CONDITION,
            payload: { integrationID: integrationID },
          })
        }
      >
        Filter
      </Button>
    </div>
  );
};

export default BlueprintLeftPanelSelectiveSync;
