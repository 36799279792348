import { useState, useEffect, useRef, createRef, useMemo } from "react";
import BlueprintStepCard from "./BlueprintStepCard";
import useBlueprintContext from "../context/useBlueprintContext";
import BlueprintGhostStepCard from "./BlueprintGhostStepCard";
import {
  BlueprintCanvasConfiguration,
  getBlueprintCanvasConfiguration,
  StepPlacementType,
} from "../utils/BlueprintCanvasUtils";
import { useWindowSize } from "../../shared/hooks/useWindowSize";
import BlueprintTriggerCard from "./BlueprintTriggerCard";
import BlueprintScraperCard from "./BlueprintScraperCard";
import { getAllCollapsedStepIDs, getParentStepToIDList } from "../utils/BlueprintEditorUtils";
import StepNote from "../../../models/StepNote";
import { useCookies } from "react-cookie";
import { LEFT_ALIGN_COOKIE_KEY, StepLogIterationInfo } from "../../../models/Blueprints";
import useBlueprintCanvasBaseStepLogs from "./hooks/useBlueprintCanvasBaseStepLogs";
import { MergeFlagFeature, useMergeFlag } from "../../shared/hooks/useMergeFlag";
import useBlueprintCanvasViewableStepLogs from "./hooks/useBlueprintCanvasViewableStepLogs";
import { findIndexInRawLogsFromSelectedIteration } from "./utils/baseStepLogsSelectionUtils";
import BlueprintCanvasArrows from "./BlueprintCanvasArrows";

const CARD_X_STEP = 180;
const CARD_Y_STEP = 150;

const BlueprintCanvas = () => {
  // Flag for displaying step I/O in canvas
  const { enabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_STEP_IO_FOR_CANVAS,
    isEnabledForUser: true,
  });

  const {
    blueprint,
    blueprintTrigger,
    blueprintRunnerExecutionResponse,
    selectedStep,
    setSelectedStep,
    selectedSteps,
    selectedStepLog,
    setSelectedStepLog,
  } = useBlueprintContext();

  const [canvasConfiguration, setCanvasConfiguration] = useState<BlueprintCanvasConfiguration>(
    getBlueprintCanvasConfiguration(blueprint, selectedStep, blueprintTrigger)
  );

  /* ---- START - PROCESS STEP I/O LOGS FOR CANVAS ---- */
  const {
    baseStepLogsTreeForCanvas,
    baseStepLogsListForCanvas,
    generateBaseStepLogsForCanvas,
    resetBaseStepLogsForCanvas,
  } = useBlueprintCanvasBaseStepLogs();
  const {
    viewableStepLogsForCanvas,
    generateViewableStepLogs,
    resetViewableStepLogs,
  } = useBlueprintCanvasViewableStepLogs();

  // Listen for changes in runner execution response, to update base canvas step logs
  useEffect(() => {
    if (enabled) {
      if (!blueprintRunnerExecutionResponse) {
        resetBaseStepLogsForCanvas();
      } else {
        generateBaseStepLogsForCanvas(
          blueprintRunnerExecutionResponse,
          canvasConfiguration,
          blueprint
        );
      }
    }
  }, [blueprintRunnerExecutionResponse]);

  // Listen for updates in base logs & selected log
  // Need both to derive the step I/O logs to show in canvas
  useEffect(() => {
    if (baseStepLogsTreeForCanvas && baseStepLogsListForCanvas) {
      generateViewableStepLogs(baseStepLogsListForCanvas, selectedStepLog);
    } else {
      resetViewableStepLogs();
    }
  }, [baseStepLogsTreeForCanvas, baseStepLogsListForCanvas, selectedStepLog]);

  // Handle selecting step log from "Iteration #" or "Page #"
  const handleSelectStepLogFromIteration = (
    stepID: string,
    selectedIterationInfo: StepLogIterationInfo
  ) => {
    if (baseStepLogsTreeForCanvas) {
      const indexOfSelectedStepLog = findIndexInRawLogsFromSelectedIteration(
        stepID,
        selectedIterationInfo,
        baseStepLogsTreeForCanvas
      );
      if (indexOfSelectedStepLog !== undefined) {
        setSelectedStepLog({
          step_id: stepID,
          index_in_raw_logs: indexOfSelectedStepLog,
        });
      }
    }
  };

  /* ---- END - PROCESS STEP I/O LOGS FOR CANVAS ---- */

  // Set initial selected step ID
  // Used for 1) highlighting parents & descendants, 2) highlighting step if query param passed in URL
  let initialSelectedStepID = selectedStep?.id ?? "";
  const parentStepToIDList = useMemo(() => getParentStepToIDList(blueprint?.steps), [
    blueprint?.steps,
  ]);
  const selectedStepDescendants = useMemo(() => parentStepToIDList[initialSelectedStepID] ?? [], [
    initialSelectedStepID,
    parentStepToIDList,
  ]);

  const collapsedSteps = getAllCollapsedStepIDs(blueprint);
  const canvasRef = useRef<HTMLDivElement>(null);

  /* ---- BEGIN - AUTO-SCROLLING TO STEPS ---- */
  // Initialize step refs, to enable auto-scrolling to a step
  const stepRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

  // Set step refs on initial page load
  useEffect(() => {
    Object.keys(canvasConfiguration.stepPlacements).forEach((stepID) => {
      stepRefs.current[stepID] = stepRefs.current[stepID] || createRef();
    });
  }, []);

  // Function to scroll to a specific ref based on query param "step"
  const scrollToStep = (stepID: string) => {
    if (!!stepRefs?.current?.[stepID]?.current) {
      stepRefs.current[stepID]?.current?.scrollIntoView({ behavior: "smooth" });
      const matchingStepPlacement = canvasConfiguration.stepPlacements?.[stepID];
      if (matchingStepPlacement) {
        switch (matchingStepPlacement.stepPlacementType) {
          case StepPlacementType.EXISTING:
          case StepPlacementType.GHOST:
            setSelectedStep(matchingStepPlacement.step);
            initialSelectedStepID = matchingStepPlacement.step.id;
            break;
          case StepPlacementType.TRIGGER:
            setSelectedStep(matchingStepPlacement.trigger);
            initialSelectedStepID = "";
            break;
          case StepPlacementType.SCRAPER:
            setSelectedStep(matchingStepPlacement.scraper);
            initialSelectedStepID = matchingStepPlacement.scraper.id;
            break;
        }
      }
    }
  };

  // Listen for query param on initial page load
  useEffect(() => {
    const handleQueryParamChange = () => {
      // Wait for 1 second before scrolling to allow for DOM updates
      setTimeout(() => {
        const params = new URLSearchParams(window.location.search);
        const stepToFocus = params.get("step");
        if (stepToFocus) {
          scrollToStep(stepToFocus);
        }
      }, 1000);
    };
    handleQueryParamChange();
    window.addEventListener("querychange", handleQueryParamChange);
    return () => {
      window.removeEventListener("querychange", handleQueryParamChange);
    };
  }, []);

  // Listen for changes in selectedStepLog, so that we can auto-scroll to it
  useEffect(() => {
    if (selectedStepLog) {
      scrollToStep(selectedStepLog.step_id);
    }
  }, [selectedStepLog]);

  /* ---- END - AUTO-SCROLLING TO STEPS ---- */

  const [cookies] = useCookies([LEFT_ALIGN_COOKIE_KEY]);

  const { width } = useWindowSize();
  // This positions the blueprint symmetrically in the canvas view.

  const cardXStart = cookies.leftAlignSteps
    ? 40
    : Math.max(((width ?? 0) - 1050 - canvasConfiguration.totalX * CARD_X_STEP) / 2, 25);

  // Recalculate positioning whenever blueprint or selected step updates.
  useEffect(() => {
    const newConfig = getBlueprintCanvasConfiguration(blueprint, selectedStep, blueprintTrigger);
    setCanvasConfiguration(newConfig);
  }, [blueprint, selectedStep, blueprintTrigger, cookies.leftAlignSteps]);

  const stepNotes: { [stepId: string]: string } = {};
  blueprint.step_notes.forEach(
    (substep: StepNote) =>
      !collapsedSteps.includes(substep.step_id) && (stepNotes[substep.step_id] = substep.text)
  );

  return (
    <div className="blueprint-canvas" ref={canvasRef}>
      <BlueprintCanvasArrows
        blueprint={blueprint}
        canvasConfiguration={canvasConfiguration}
        cardXStart={cardXStart}
        canvasRef={canvasRef}
        leftAlignSteps={cookies.leftAlignSteps}
      />
      {Object.entries(canvasConfiguration.stepPlacements).map(([stepID, stepPlacement]) => {
        return (
          <div
            ref={stepRefs.current[stepID]}
            key={stepID}
            style={{
              position: "absolute",
              left: `${cardXStart + stepPlacement.xIndex * CARD_X_STEP}px`,
              top: `${
                stepPlacement.yIndex * CARD_Y_STEP +
                (stepPlacement.stepPlacementType === StepPlacementType.EXISTING ? 0 : 40)
              }px`,
              transition: "all 0.5s",
            }}
          >
            {stepPlacement.stepPlacementType === StepPlacementType.TRIGGER ? (
              <BlueprintTriggerCard
                trigger={stepPlacement.trigger}
                operationType={blueprint.operation_type}
                triggerType={blueprint.trigger_type}
              />
            ) : stepPlacement.stepPlacementType === StepPlacementType.SCRAPER ? (
              <BlueprintScraperCard scraper={stepPlacement.scraper} />
            ) : stepPlacement.stepPlacementType === StepPlacementType.GHOST ? (
              <BlueprintGhostStepCard ghostStep={stepPlacement.step} />
            ) : (
              <BlueprintStepCard
                isHighlighted={
                  initialSelectedStepID === stepPlacement.step.id ||
                  selectedStep === stepPlacement.step ||
                  (selectedSteps ?? []).includes(stepPlacement.step)
                }
                isDescendantofSelectedStep={selectedStepDescendants.includes(stepPlacement.step.id)}
                step={stepPlacement.step}
                stepNote={stepNotes[stepID]}
                stepLog={viewableStepLogsForCanvas ? viewableStepLogsForCanvas[stepID] : undefined}
                handleSelectStepLogFromIteration={handleSelectStepLogFromIteration}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default BlueprintCanvas;
