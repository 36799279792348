import { Dialog, Card, Text, ButtonVariant, Spinner } from "@merge-api/merge-javascript-shared";
import { useEffect, useState } from "react";
import { APIEndpointParameter } from "src/autogenerated-types/AUTOGENERATED_ExpandedPydantic_APIEndpointParameter";
import {
  UserFacingFilterDetails,
  APIEndpointParameterMapping,
  VersionedComponentInfo,
  UserFacingFilterType,
} from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import useSelectiveSyncFilterBuilderContext from "../../context/useSelectiveSyncFilterBuilderContext";
import useLoadFilterTypes from "../selective-sync-filter-type-configuration/hooks/useLoadFilterTypes";
import CommonModelDisplay from "../shared/CommonModelDisplay";
import APIEndpointParameterForm from "./APIEndpointParameterForm";
import EditAPIEndpointParameterContainer from "./EditAPIEndpointParameterContainer";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";

type AvailableFilterTypes = {
  [id: string]: UserFacingFilterType;
};
interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  isEditingExistingAPIEndpointParameter: boolean;
  userFacingDetails: UserFacingFilterDetails;
  existingParameterMappingDetails?: APIEndpointParameterMapping;
  existingAPIEndpointParameter?: APIEndpointParameter | null;
  addOrUpdateAPIEndpointParameter: (newAPIEndpointParameter: VersionedComponentInfo) => void;
  isDisabled?: boolean;
}

const EMPTY_FILTER_MAPPING_DETAILS = {
  id: "",
  value_transformation: null,
};

const EMPTY_API_ENDPOINT_FILTER = {
  composite_filter_information: null,
  id: "",
};

const APIEndpointParameterModal = ({
  existingParameterMappingDetails,
  isEditingExistingAPIEndpointParameter,
  userFacingDetails,
  existingAPIEndpointParameter,
  isModalOpen,
  addOrUpdateAPIEndpointParameter,
  setIsModalOpen,
  isDisabled,
}: Props) => {
  const { integration } = useIntegrationBuilderContext();
  const { integrationID } = useSelectiveSyncFilterBuilderContext();
  const [onSubmit, setOnSubmit] = useState<(() => void) | null>(null);
  const [integrationFilterTypes, setIntegrationFilterTypes] = useState<AvailableFilterTypes>({});
  const parameterMappingDetails: APIEndpointParameterMapping =
    existingParameterMappingDetails ?? EMPTY_FILTER_MAPPING_DETAILS;

  const { loadFilterTypes, isLoadingFilterTypes } = useLoadFilterTypes({
    integrationID,
    setIntegrationFilterTypes,
  });

  // This useEffect is used to fetch available filter types from our backend
  // so that users can choose which filter type to associate a new S2 filter
  // schema to. If we already have a filterType connected to the S2 filter type schema,
  // we block them from changing it (for now)
  useEffect(() => {
    loadFilterTypes();
  }, [integrationID, setIntegrationFilterTypes]);

  const findCurrentFilterType = (): UserFacingFilterType | undefined => {
    return Object.values(integrationFilterTypes).find(
      (filter) => filter.id == userFacingDetails?.filter_type_id?.id
    );
  };

  return (
    <Dialog
      onSecondaryButtonClick={() => setIsModalOpen(false)}
      variant="lg"
      title={
        isEditingExistingAPIEndpointParameter
          ? `Select existing ${integration?.name} API Endpoint Filter`
          : `Create new ${integration?.name} API Endpoint Filter`
      }
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      primaryButtonText={`Save`}
      primaryButtonVariant={ButtonVariant.PrimaryBlue}
      onPrimaryButtonClick={() => (onSubmit ? onSubmit() : () => {})}
      primaryButtonDisabled={!!isDisabled}
    >
      <div className="flex flex-col max-h-[700px] overflow-auto space-y-4 px-1 pb-4">
        <Text variant="md">
          {isEditingExistingAPIEndpointParameter
            ? `Select an existing ${integration?.name} API Endpoint Filter. The endpoint you select will have filtering applied for this Selective Sync filter. You can modify the filter later.`
            : `Create a ${integration?.name} API Endpoint Filter. The endpoint you select will have filtering applied for this Selective Sync filter. You can modify the filter later.`}
        </Text>
        <div className="flex flex-col space-y-2">
          <Text variant="h6">Selective Sync Filter</Text>
          <Card variant="outline" className="p-3 mt-2 mb-4">
            {isLoadingFilterTypes ? (
              <Spinner size="md" />
            ) : (
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <CommonModelDisplay
                    isBold
                    alignIconLeft={true}
                    commonModelID={findCurrentFilterType()?.common_model_class_id ?? ""}
                  />
                </div>
                <Text variant="sm-mono">{userFacingDetails?.filter_type_id?.filter_name}</Text>
              </div>
            )}
          </Card>
        </div>
        <div className="flex flex-col space-y-2">
          <Text variant="h6">API Endpoint Filter</Text>
          {isEditingExistingAPIEndpointParameter ? (
            <EditAPIEndpointParameterContainer
              parameterMappingDetails={parameterMappingDetails}
              userFacingDetails={userFacingDetails}
              existingAPIEndpointParameter={existingAPIEndpointParameter}
              setOnSubmit={setOnSubmit as () => void}
              setIsModalOpen={setIsModalOpen}
              addOrUpdateAPIEndpointParameter={addOrUpdateAPIEndpointParameter}
            />
          ) : (
            <APIEndpointParameterForm
              userFacingDetails={userFacingDetails}
              apiEndpointParameter={existingAPIEndpointParameter ?? EMPTY_API_ENDPOINT_FILTER}
              setOnSubmit={setOnSubmit as () => void}
              isEditingExistingAPIEndpointParameter={!!existingAPIEndpointParameter}
              setIsModalOpen={setIsModalOpen}
              addOrUpdateAPIEndpointParameter={addOrUpdateAPIEndpointParameter}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default APIEndpointParameterModal;
