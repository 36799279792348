import clsx from "clsx";
import { Alert, Text } from "@merge-api/merge-javascript-shared";
import { Info } from "lucide-react";
import { useEffect, useState } from "react";
import { SelectiveSyncFilterSchema } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { ComponentState, VersionedComponentInfo } from "../../../../versioned-components/types";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";
import RequiredFieldAsterisk from "../../../shared/RequiredFieldAsterisk";
import selectiveSyncSchemaFilterReducerActions, {
  EMPTY_SELECTIVE_SYNC_FILTER_SCHEMA,
} from "../../reducer/selectiveSyncSchemaFilterReducerActions";
import useVersionedComponentReducer from "../../reducer/useVersionedComponentReducer";
import useCreateOrUpdateSelectiveSyncFilterSchema from "../../useCreateOrUpdateSelectiveSyncFilterSchema";
import ParameterMappingDetailsContainer from "../parameter-mapping-details/ParameterMappingDetailsContainer";
import SelectiveSyncFilterUserFacingFilterDetails from "../selective-sync-filter-type-configuration/SelectiveSyncFilterUserFacingFilterDetails";
import { v4 as uuidv4 } from "uuid";
import { DiffModelTypeEnum } from "../../../../../models/DiffModels";
import { useHistory, useParams } from "react-router-dom";
import {
  navigateToIntegrationBuilderSelectiveSyncFilterBuilder,
  navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId,
} from "../../../../../router/RouterUtils";
import SelectiveSyncFilterSchemaFormHeader from "./SelectiveSyncFilterSchemaFormHeader";

interface Props {
  selectiveSyncFilterSchemaData?: VersionedComponentInfo<SelectiveSyncFilterSchema> | null;
  integrationID: string;
}

type RouteParams = {
  selectiveSyncFilterSchemaId?: string;
};

const SelectiveSyncFilterSchemaForm = ({ selectiveSyncFilterSchemaData, integrationID }: Props) => {
  const history = useHistory();
  const { selectiveSyncFilterSchemaId } = useParams<RouteParams>();
  const isEditingExistingS2FilterSchema = !!selectiveSyncFilterSchemaId;

  const [isMissingRequiredFields, setIsMissingRequiredFields] = useState<boolean>(
    !isEditingExistingS2FilterSchema
  );

  const selectiveSyncFilterSchema =
    selectiveSyncFilterSchemaData?.next_version ??
    selectiveSyncFilterSchemaData?.published_version ??
    EMPTY_SELECTIVE_SYNC_FILTER_SCHEMA;

  const selectiveSyncFilterSchemaUnderConstruction: SelectiveSyncFilterSchema = {
    ...selectiveSyncFilterSchema,
  };

  const navigateToSelectiveSyncFilterView = (
    componentID: string = selectiveSyncFilterSchemaId ?? ""
  ) => {
    navigateToIntegrationBuilderSelectiveSyncFilterBuilderForId(
      history,
      integrationID,
      false,
      componentID ?? ""
    );
  };

  // ----------------------------------------------
  // CREATE/UPDATE SELECTIVE SYNC FILTER SCHEMA API
  // ----------------------------------------------
  const {
    createSelectiveSyncFilterSchema,
    patchSelectiveSyncFilterSchema,
    stageSelectiveSyncFilterSchema,
    unstageSelectiveSyncFilterSchema,
    deleteSelectiveSyncFilterSchema,
    deleteDraftSelectiveSyncFilterSchema,
    restoreSelectiveSyncFilterSchema,
  } = useCreateOrUpdateSelectiveSyncFilterSchema();

  // ----------------------------------------------
  // SELECTIVE SYNC FILTER SCHEMA - REDUCER ACTIONS
  // ----------------------------------------------
  const [selectiveSyncFilterSchemaComponentState, reducerActions] = useVersionedComponentReducer<
    SelectiveSyncFilterSchema
  >({
    versionedComponentUnderConstruction: selectiveSyncFilterSchemaUnderConstruction,
    componentState:
      selectiveSyncFilterSchemaData?.component_state ??
      ComponentState.NEW_COMPONENT_WITH_DRAFT_VERSION,
  });

  const {
    addFilterOperator,
    removeFilterOperator,
    setFilterTypeID,
    setRemoteKeyName,
    addOrUpdateAPIEndpointParameter,
    removeParameterMappingDetails,
    updateValueTransformation,
    setAllowManualFilterMappingInBlueprints,
    updatePublicFacingStatus,
  } = selectiveSyncSchemaFilterReducerActions(
    selectiveSyncFilterSchemaComponentState,
    reducerActions
  );

  // ----------------------------------------------
  // -------- INTEGRATION BUILDER CONTEXT ---------
  // ----------------------------------------------
  const {
    setOnSubmit,
    setCurrentStateForDiff,
    setNewStateForDiff,
    setOnStage,
    setCanStage,
    setShouldRenderSubmitButton,
    setShouldRenderStageButton,
    setStageButtonText,
    resetDiffStates,
    setModelTypeForDiff,
    setCanSubmit,
    setMarkedForDeletion,
    isRightPanelOpen,
  } = useIntegrationBuilderContext();

  useIntegrationBuilderContext({
    isGatedByPermissioning: false,
    hasPermissionToEdit: true,
    shouldRenderNavigationButtons: false,
    submitButtonText: "Save draft",
    modelTypeForDiff: DiffModelTypeEnum.SELECTIVE_SYNC_FILTER_SCHEMA,
    shouldHideDiffModal: false,
  });

  // Check if our component state is staged
  const isStagedComponent = (componentState?: ComponentState) =>
    [
      ComponentState.PUBLISHED_WITH_NEW_STAGED_VERSION,
      ComponentState.NEW_COMPONENT_WITH_STAGED_VERSION,
    ].includes(
      (componentState as ComponentState) ?? selectiveSyncFilterSchemaComponentState?.componentState
    );

  const isDraftComponent = (componentState?: ComponentState) =>
    [
      ComponentState.PUBLISHED_WITH_NEW_DRAFT,
      ComponentState.NEW_COMPONENT_WITH_DRAFT_VERSION,
    ].includes(
      (componentState as ComponentState) ?? selectiveSyncFilterSchemaComponentState?.componentState
    );

  const isComponentMarkedForDeletion =
    selectiveSyncFilterSchemaComponentState?.componentState &&
    selectiveSyncFilterSchemaComponentState.componentState === ComponentState.MARKED_FOR_DELETION;

  const selectiveSyncFilterSchemaOnStage = (componentState?: ComponentState | undefined) => {
    if (!isEditingExistingS2FilterSchema) return;

    const requestPayload = {
      componentID: selectiveSyncFilterSchemaId,
      integrationID,
      onResponse: (data: any) => {
        const newComponentState = data?.component_state;
        reducerActions.updateComponentState(newComponentState);
        navigateToSelectiveSyncFilterView(selectiveSyncFilterSchemaId);
        setStageButtonText(isStagedComponent(newComponentState) ? "Unstage" : "Stage");
      },
    };

    isStagedComponent(componentState)
      ? unstageSelectiveSyncFilterSchema(requestPayload)
      : stageSelectiveSyncFilterSchema(requestPayload);
  };

  const selectiveSyncFilterSchemaOnSubmit = (
    selectiveSyncFilterSchemaPayload: SelectiveSyncFilterSchema | null
  ) => {
    if (!selectiveSyncFilterSchemaPayload) return;

    if (!isEditingExistingS2FilterSchema) {
      const componentID = uuidv4();
      selectiveSyncFilterSchemaPayload.id = componentID;
    }

    const requestPayload = {
      selectiveSyncFilterSchema: selectiveSyncFilterSchemaPayload,
      integrationID,
      onResponse: (data: any) => {
        resetDiffStates();
        setModelTypeForDiff(undefined);
        navigateToSelectiveSyncFilterView(selectiveSyncFilterSchemaPayload.id);
        reducerActions.updateComponentState(data?.component_state);
      },
    };

    isEditingExistingS2FilterSchema
      ? patchSelectiveSyncFilterSchema(requestPayload)
      : createSelectiveSyncFilterSchema(requestPayload);
  };

  // Set initial state of our schema
  useEffect(() => {
    const componentState = selectiveSyncFilterSchemaComponentState?.componentState;
    setCurrentStateForDiff(selectiveSyncFilterSchemaUnderConstruction);
    setOnStage(() => selectiveSyncFilterSchemaOnStage(componentState));
    setStageButtonText(isStagedComponent() ? "Unstage" : "Stage");

    // We must have a valid staged or drafted component in order stage / unstage.
    const canStage =
      !!selectiveSyncFilterSchemaId &&
      (isDraftComponent(componentState) || isStagedComponent(componentState));
    setCanStage(canStage);
    setShouldRenderStageButton(!isComponentMarkedForDeletion);

    // We can't save a draft if a component is already staged.  We also need to have
    // all of the required fields filled out.
    const canSubmit = !isStagedComponent(componentState) && !isMissingRequiredFields;
    setCanSubmit(canSubmit);
    setShouldRenderSubmitButton(!isComponentMarkedForDeletion);
    setMarkedForDeletion(!!isComponentMarkedForDeletion);
  }, [selectiveSyncFilterSchemaComponentState?.componentState, isMissingRequiredFields]);

  // Any time the selective sync filter schema we're building changes, update
  // the onSubmit function we would call when sending the filter schema to our database
  useEffect(() => {
    setOnSubmit(() =>
      selectiveSyncFilterSchemaOnSubmit(
        selectiveSyncFilterSchemaComponentState?.versionedComponentUnderConstruction ?? null
      )
    );
    setNewStateForDiff(
      selectiveSyncFilterSchemaComponentState?.versionedComponentUnderConstruction ?? {}
    );
  }, [setOnSubmit, selectiveSyncFilterSchemaComponentState?.versionedComponentUnderConstruction]);

  const onDelete = () => {
    const idToDelete = selectiveSyncFilterSchemaId;

    if (idToDelete) {
      if (isDraftComponent(selectiveSyncFilterSchemaData?.component_state)) {
        deleteDraftSelectiveSyncFilterSchema({
          integrationID,
          componentID: idToDelete,
          successToastMessage: `Successfully deleted Selective Sync filter schema with ID ${idToDelete}`,
          onResponse: (_: any) => {
            navigateToIntegrationBuilderSelectiveSyncFilterBuilder(history, integrationID);
          },
        });
      } else {
        deleteSelectiveSyncFilterSchema({
          integrationID,
          componentID: idToDelete,
          successToastMessage: `Successfully deleted Selective Sync filter schema with ID ${idToDelete}`,
          onResponse: (_: any) => {
            navigateToIntegrationBuilderSelectiveSyncFilterBuilder(history, integrationID);
          },
        });
      }
    }
  };

  const onRestore = () => {
    const idToRestore = selectiveSyncFilterSchemaId;

    if (idToRestore) {
      restoreSelectiveSyncFilterSchema({
        integrationID,
        componentID: idToRestore,
        successToastMessage: `Successfully restored Selective Sync filter schema with ID ${idToRestore}`,
        onResponse: (_: any) => {
          navigateToIntegrationBuilderSelectiveSyncFilterBuilder(history, integrationID);
        },
      });
    }
  };
  return (
    <div className={clsx(isRightPanelOpen && "my-10 ml-10 mr-5")}>
      <SelectiveSyncFilterSchemaFormHeader
        componentState={selectiveSyncFilterSchemaComponentState?.componentState}
        onDelete={onDelete}
        onRestore={onRestore}
        selectiveSyncFilterSchema={
          selectiveSyncFilterSchemaComponentState?.versionedComponentUnderConstruction
        }
      />
      <div className={clsx("flex flex-col", isComponentMarkedForDeletion && "!text-gray-60")}>
        <div className="flex flex-row items-center mb-3">
          {!selectiveSyncFilterSchema && (
            <RequiredFieldAsterisk showText={!selectiveSyncFilterSchema} />
          )}
        </div>
        <Alert
          icon={<Info size={16} />}
          title="What is a Selective Sync Filter?"
          color="gray"
          className="mb-6"
        >
          <Text>
            A selective sync filter is a collection of models which let us specify specific criteria
            for an integration, common model, and common model (or 3rd party) field that must be
            satisfied in order for us to sync that data from a 3rd party. <br /> For example, a
            selective sync filter could be be configured to only sync Employee data from Workday,
            where Workday’s modifiedAt field (which maps to our Employee model’s modified_at field)
            is greater than Jun. 20, 2024.
          </Text>
        </Alert>
        <SelectiveSyncFilterUserFacingFilterDetails
          filterDetails={
            selectiveSyncFilterSchemaComponentState?.versionedComponentUnderConstruction
              ?.user_facing_filter_details ?? null
          }
          setFilterTypeID={setFilterTypeID}
          isEditingExistingS2FilterSchema={isEditingExistingS2FilterSchema}
          setRemoteKeyName={setRemoteKeyName}
          setIsMissingRequiredFields={setIsMissingRequiredFields}
          updatePublicFacingStatus={updatePublicFacingStatus}
        />
        <ParameterMappingDetailsContainer
          parameterMappingDetails={
            selectiveSyncFilterSchemaComponentState?.versionedComponentUnderConstruction
              ?.filter_mapping_details
          }
          addFilterOperator={addFilterOperator}
          removeFilterOperator={removeFilterOperator}
          userFacingDetails={
            selectiveSyncFilterSchemaComponentState?.versionedComponentUnderConstruction
              ?.user_facing_filter_details ?? null
          }
          addOrUpdateAPIEndpointParameter={addOrUpdateAPIEndpointParameter}
          removeParameterMappingDetails={removeParameterMappingDetails}
          updateValueTransformation={updateValueTransformation}
          setAllowManualFilterMappingInBlueprints={setAllowManualFilterMappingInBlueprints}
        />
      </div>
    </div>
  );
};

export default SelectiveSyncFilterSchemaForm;
