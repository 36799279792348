import { Alert } from "@merge-api/merge-javascript-shared";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  APIEndpointParameter,
  CompositeFilterType,
} from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_APIEndpointParameter";
import { UserFacingFilterDetails } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import { RequestLocation } from "../../../../../autogenerated-types/AUTOGENERATED_NativePydantic_APIEndpointParameter";
import { APIEndpoint } from "../../../../integrations/versioned-components/types";
import {
  ComponentState,
  NextVersionState,
  VersionedComponentInfo,
} from "../../../../versioned-components/types";
import TextFieldHeader from "../../../shared/TextFieldHeader";
import TypeaheadHeader from "../../../shared/TypeaheadHeader";
import useSelectiveSyncFilterBuilderContext from "../../context/useSelectiveSyncFilterBuilderContext";
import { CompositeFilterEnum, FilterLocationEnum } from "../../types";
import { shallowEqual } from "../../utils";
import APIEndpointsTypeahead from "./APIEndpointsTypeahead";
import useCreateOrUpdateAPIEndpointParameter from "./useCreateOrUpdateAPIEndpointParameter";
import BinaryChoiceButtons from "../../../authentication/components/shared/BinaryChoiceButtons";
import LineIndent from "../../../authentication/components/shared/LineIndent";
import SelectHeader from "../../../shared/SelectHeader";
import useIntegrationBuilderContext from "../../../context/useIntegrationBuilderContext";

interface Props {
  userFacingDetails: UserFacingFilterDetails;
  apiEndpointParameter: APIEndpointParameter;
  isEditingExistingAPIEndpointParameter: boolean;
  setOnSubmit: React.Dispatch<React.SetStateAction<() => void>>;
  setIsModalOpen: (value: boolean) => void;
  addOrUpdateAPIEndpointParameter: (
    newAPIEndpointParameter: VersionedComponentInfo<APIEndpointParameter>
  ) => void;
}

const APIEndpointParameterForm = ({
  apiEndpointParameter,
  setOnSubmit,
  isEditingExistingAPIEndpointParameter,
  setIsModalOpen,
  addOrUpdateAPIEndpointParameter,
}: Props) => {
  const { integration } = useIntegrationBuilderContext();
  const { integrationID } = useSelectiveSyncFilterBuilderContext();
  const apiEndpointParameterUnderConstruction: APIEndpointParameter = {
    ...apiEndpointParameter,
  };
  const {
    patchAPIEndpointParameter,
    createAndStageAPIEndpointParameter,
  } = useCreateOrUpdateAPIEndpointParameter();

  /** START - STATES */
  const [apiEndpointInfo, setAPIEndpointInfo] = useState<APIEndpoint | null>(
    apiEndpointParameterUnderConstruction?.api_endpoint_id ?? null
  );
  const [filterLocation, setFilterLocation] = useState<string>(
    apiEndpointParameterUnderConstruction?.request_location ?? ""
  );
  const [thirdPartyKeyName, setThirdPartyKeyName] = useState<string | null>(
    apiEndpointParameterUnderConstruction?.key_path?.[0] ?? null
  );
  const [isCompositeFilter, setIsCompositeFilter] = useState<boolean | undefined>(
    !!apiEndpointParameterUnderConstruction?.composite_filter_information?.composite_filter_type
  );
  const [compositeFilterType, setCompositeFilterType] = useState<CompositeFilterType | undefined>(
    apiEndpointParameterUnderConstruction?.composite_filter_information?.composite_filter_type
  );
  /** END - STATES */

  /** START - Handle inter-dependent state changes */
  useEffect(() => {
    if (!isCompositeFilter) setCompositeFilterType(undefined);
  }, [isCompositeFilter]);
  /** END - Handle inter-dependent state changes */

  /** START - TO SHOW ALERT IF ANY CHANGES */
  const originalValues = {
    apiEndpointInfo,
    filterLocation,
    thirdPartyKeyName,
    compositeFilterType,
  };

  const hasChanges = () =>
    !shallowEqual(originalValues, {
      apiEndpointInfo: apiEndpointParameter?.api_endpoint_id,
      filterLocation: apiEndpointParameter?.request_location,
      thirdPartyKeyName: apiEndpointParameter?.key_path?.[0],
      compositeFilterType:
        apiEndpointParameter?.composite_filter_information?.composite_filter_type,
    });
  /** END - TO SHOW ALERT IF ANY CHANGES */

  /** START - SAVE CHANGES */
  // Function to update frontend components, once APIEndpointParameter itself is saved in backend
  const saveAPIEndpointParameterOnResponse = (madePatchRequest: boolean, id: string, data: any) => {
    const responseKey = isEditingExistingAPIEndpointParameter ? id : "component";

    if (madePatchRequest) {
      const versionedComponentInfo = data?.[responseKey];

      addOrUpdateAPIEndpointParameter(
        versionedComponentInfo as VersionedComponentInfo<APIEndpointParameter>
      );
    } else {
      const apiEndpointFilterResponse = data?.[responseKey];
      const versionedComponentInfo = {
        next_version: apiEndpointFilterResponse,
        next_version_state: NextVersionState.STAGED_WITH_CHANGES,
        published_version: null,
        component_state: ComponentState.NEW_COMPONENT_WITH_STAGED_VERSION,
      };

      addOrUpdateAPIEndpointParameter(
        versionedComponentInfo as VersionedComponentInfo<APIEndpointParameter>
      );
    }

    setIsModalOpen(false);
  };

  // Function to actually save APIEndpointParameter in backend
  const saveAPIEndpointParameter = () => {
    const id = apiEndpointParameterUnderConstruction?.id
      ? apiEndpointParameterUnderConstruction?.id
      : uuidv4();

    const apiEndpointFilterPayload: APIEndpointParameter = {
      ...apiEndpointParameterUnderConstruction,
      id,
      request_location: filterLocation as RequestLocation,
      key_path: [thirdPartyKeyName ?? ""],
      api_endpoint_id: apiEndpointInfo as APIEndpoint,
      composite_filter_information:
        isCompositeFilter && compositeFilterType
          ? {
              composite_filter_type: compositeFilterType,
            }
          : null,
    };

    apiEndpointFilterPayload.id = id;

    const requestPayload = {
      apiEndpointParameter: apiEndpointFilterPayload,
      integrationID,
      onResponse: (data: any) => {
        saveAPIEndpointParameterOnResponse(isEditingExistingAPIEndpointParameter, id, data);
      },
    };

    isEditingExistingAPIEndpointParameter
      ? patchAPIEndpointParameter(requestPayload)
      : createAndStageAPIEndpointParameter(requestPayload);
  };

  // This useEffect listens for any changes we make to the editable
  // api endpoint filter fields, and makes sure that the save button in
  // our modal will submit the correct information
  useEffect(() => {
    setOnSubmit(() => saveAPIEndpointParameter);
  }, [filterLocation, thirdPartyKeyName, apiEndpointInfo, isCompositeFilter, compositeFilterType]);
  /** END - SAVE CHANGES */

  return (
    <LineIndent className="mt-6">
      <div className="flex flex-col space-y-4 pr-4">
        {isEditingExistingAPIEndpointParameter && hasChanges() && (
          <Alert showWarningIcon={true} color="red">
            Any changes made here will be reflected across all instances of this third-party API
            endpoint parameter
          </Alert>
        )}
        <APIEndpointsTypeahead
          integrationID={integrationID}
          apiEndpointInfo={apiEndpointInfo}
          setAPIEndpointInfo={setAPIEndpointInfo}
          title={`${integration ? integration.name : "3rd-party"} API endpoint`}
        />
        <TypeaheadHeader
          title="Location in API endpoint"
          subtitle="This specifies where in our API requests we will be adding the selective sync value, e.g. as a query parameter, or a request body value, etc."
          value={filterLocation}
          options={Object.values(FilterLocationEnum).map((val) => val)}
          onChange={(_: any, location: any) => {
            setFilterLocation(location);
          }}
        />
        <TextFieldHeader
          title="Key in API endpoint"
          subtitle="The key to insert the selective sync value into the API request"
          value={thirdPartyKeyName ?? ""}
          onChange={(e) => setThirdPartyKeyName(e.target.value)}
        />
        <BinaryChoiceButtons
          title="Is this a composite filter?"
          subtitle="Select 'Yes' if the engineering team has built a composite filter translation engine for this integration's endpoint. Composite filter engines are used when translations of Selective Sync filters to 3rd-party filters are more complex than just appending query params or body param keys. Examples are Intercom and Hubspot."
          binaryChoice={isCompositeFilter}
          setBinaryChoice={setIsCompositeFilter}
        />
        {isCompositeFilter && (
          <LineIndent>
            <SelectHeader
              title="Composite filter type"
              subtitle="These are composite filter translation engines built by engineering team. They translate Selective Sync filter selections to a specific integration's endpoint's schema or a standardized schema format."
              options={Object.values(CompositeFilterEnum) as string[]}
              value={compositeFilterType}
              onChange={(_: any, selectedOption: string | null | undefined) =>
                setCompositeFilterType(selectedOption as CompositeFilterType)
              }
            />
          </LineIndent>
        )}
      </div>
    </LineIndent>
  );
};
export default APIEndpointParameterForm;
