import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import * as immutable from "object-path-immutable";
import { Button } from "react-bootstrap";
import {
  ALLOWED_OPERATION_TYPES_FOR_PAGINATION_TIMESTAMPS,
  CATEGORY_COMMON_MODEL_TYPES,
} from "../../../constants";
import {
  AddStepRelation,
  Blueprint,
  BlueprintComparator,
  BlueprintDataTransformStep,
  BlueprintGenericStepTemplate,
  BlueprintLogicalOperation,
  BlueprintLogicalOperator,
  BlueprintOperationType,
  BlueprintParameterSchemaValue,
  BlueprintParameterValue,
  BlueprintParameterValueConstant,
  BlueprintParameterValueCustomFunction,
  BlueprintParameterValueCustomFunctionType,
  BlueprintParameterValueProcedureArray,
  BlueprintParameterValueReturnValue,
  BlueprintParameterValueType,
  BlueprintProcedure,
  BlueprintStep,
  BlueprintStepBase,
  BlueprintStepOrGhostStepOrTriggerOrScraper,
  BlueprintStepPaths,
  BlueprintStepTemplate,
  BlueprintStepTemplateBase,
  BlueprintStepType,
  BlueprintSwitchStep,
  BlueprintTriggerIntervalUnit,
  BlueprintTriggerType,
  BlueprintWithTrigger,
  JSONObjectSchema,
  JSONSchema,
  JSONSchemaValue,
  ReportFile,
  StepRelations,
  StepCoverageLevel,
  BlueprintIndividualStepCoverage,
  StepCoverageDetails,
  BlueprintIfElseStep,
} from "../../../models/Blueprints";
import {
  apiCategoryFromString,
  blueprintProxyOperations,
  blueprintUpdateExistingModelOperations,
  blueprintWriteOperations,
} from "../../../models/Helpers";
import StepNote from "../../../models/StepNote";
import { ScraperStep, ScraperStepType, ScraperVersion } from "../../scraper/types";
import { getReturnSchemaForScraper } from "../../scraper/utils/ScraperUtils";
import { isParameterValueCustomJSON } from "../right-panel/custom-json-parameter/CustomJSONUtils";
import {
  appendProcedureParameters,
  generateReturnSchemaFromProcedureArray,
  getEmptyArrayJSONSchema,
} from "./BlueprintDataTransformUtils";
import {
  firstLetterUpperCase,
  formatCommonModelId,
  snakeCaseToFirstLetterUpperCase,
} from "../../../utils";
import { FormErrorData } from "../../../api-client/api_client";
import { showErrorToast } from "../../shared/Toasts";
import { ErrorOption } from "react-hook-form";
import { fetchBlueprintVersionForIntegration } from "../utils/BlueprintEditorAPIClient";

const GHOST_STEP_TEMPLATE_VALUE = "ghost";

export const initializeStepRelationMap = (blueprint: Blueprint) => {
  const newStepRelationMap = new Map();

  const setStepsRelationsHelper = (
    steps: Array<BlueprintStep>,
    stepRelationMap: Map<string, StepRelations>,
    parent?: BlueprintStep,
    parentPath?: string
  ) => {
    const steps_length = steps.length;
    for (const [index, step] of steps.entries()) {
      let parentStep = null;
      let predecessorStep = null;
      let successorStep = null;
      if (index === 0 && parent) {
        parentStep = parent;
      }
      if (index > 0) {
        predecessorStep = steps[index - 1];
      }
      if (index < steps_length - 1) {
        successorStep = steps[index + 1];
      }
      const stepRelations = {
        predecessor: predecessorStep,
        successor: successorStep,
        ...(parentStep && { parent: parentStep, parentPath: parentPath }),
      } as StepRelations;
      stepRelationMap.set(step.id, stepRelations);

      if (step.paths) {
        for (const [pathKey, substeps] of Object.entries(step.paths)) {
          setStepsRelationsHelper(substeps, stepRelationMap, step, pathKey);
        }
      }
    }
    return stepRelationMap;
  };

  return setStepsRelationsHelper(blueprint.steps, newStepRelationMap);
};

export const showErrorMessages = (
  err: Response | undefined,
  message: string,
  fieldErrorHandler?: (name: string, error: ErrorOption) => void
) => {
  if (err) {
    err
      ?.json()
      .then((data: FormErrorData) => {
        let displayedError = false;

        for (const field_name in data) {
          const errorMessage = data[field_name][0];
          if (field_name === "non_field_errors") {
            showErrorToast(errorMessage);
            displayedError = true;
          } else if (fieldErrorHandler) {
            fieldErrorHandler(field_name, { message: errorMessage });
          }
        }

        if (!displayedError) {
          showErrorToast(message);
        }
      })
      .catch(() => {
        showErrorToast(message);
      });
  } else {
    showErrorToast(message);
  }
};

export const getBlueprintStepForStepID = (blueprint: Blueprint, stepID: string) =>
  getStepForStepID(blueprint, stepID) as BlueprintStep | null;

// Given a blueprint and a step ID, returns the step inside the blueprint corresponding to the ID, or null if not found.
export const getStepForStepID = (
  blueprint: Blueprint | ScraperVersion,
  stepID: string
): BlueprintStep | ScraperStep | null => {
  let returnStep = null;

  const stepForStepIDHelper = (steps: Array<BlueprintStep | ScraperStep>): void => {
    for (const step of steps) {
      if (step.id === stepID) {
        returnStep = step;
      }
      const paths = step.paths;
      if (paths != null) {
        for (const [_, substeps] of Object.entries(paths)) {
          stepForStepIDHelper(substeps);
        }
      }
    }
  };

  stepForStepIDHelper(blueprint.steps);

  return returnStep;
};

export const getStepForStepIDInPath = (
  steps: Array<BlueprintStep | ScraperStep>,
  stepID: string
): BlueprintStep | ScraperStep | null => {
  let returnStep = null;

  const stepForStepIDHelper = (steps: Array<BlueprintStep | ScraperStep>): void => {
    for (const step of steps) {
      if (step.id === stepID) {
        returnStep = step;
      }
      const paths = step.paths;
      if (paths != null) {
        for (const [_, substeps] of Object.entries(paths)) {
          stepForStepIDHelper(substeps);
        }
      }
    }
  };

  stepForStepIDHelper(steps);

  return returnStep;
};

export type TraversalPath = Array<string | number>;

// Given a blueprint and a step ID, returns a traversal path to the step corresponding to the ID, or null if not found,
// where the traversal path is an array of keys and indices.
export const getStepTraversalPathForStepID = (
  blueprintOrScraper: Blueprint | ScraperVersion,
  stepID: string
): null | TraversalPath => {
  let stepTraversalArray = null;

  const stepTraversalHelper = (
    steps: Array<BlueprintStep | ScraperStep>,
    path: TraversalPath
  ): void => {
    for (const [index, step] of steps.entries()) {
      let newPath: TraversalPath = [...path, index];
      if (step.id === stepID) {
        stepTraversalArray = newPath;
      }
      const paths = step.paths;
      if (paths != null) {
        newPath = [...newPath, "paths"];
        for (const [key, substeps] of Object.entries(paths)) {
          const substepPath = [...newPath, key];
          stepTraversalHelper(substeps, substepPath);
        }
      }
    }
  };

  stepTraversalHelper(blueprintOrScraper.steps, ["steps"]);

  return stepTraversalArray;
};

export const isPaginationTimestampAvailable = (operationType: BlueprintOperationType): boolean => {
  return ALLOWED_OPERATION_TYPES_FOR_PAGINATION_TIMESTAMPS.includes(operationType);
};

export const checkPaginationTimestampUsed = (
  selectedStep: BlueprintStepOrGhostStepOrTriggerOrScraper | undefined
): boolean => {
  // Check if have a selectedStep template that is not a "ghost" (UI placeholder) template
  // If so, check if the step uses pagination timestamps. If not, return false.
  return selectedStep &&
    selectedStep.template &&
    selectedStep.template !== GHOST_STEP_TEMPLATE_VALUE
    ? selectedStep.use_pagination_timestamp || false
    : false;
};

export const getEnforceStepTraversalPathForStepID = (
  blueprint: Blueprint | ScraperVersion,
  stepID: string
): TraversalPath => getStepTraversalPathForStepID(blueprint, stepID) as TraversalPath;

export const convertTraversalPathToDotNotation = (path: TraversalPath) => path.join(".");

// Deletes a step in a blueprint given stepID, splicing the step array it's contained in
export const deleteStep = (blueprint: Blueprint, stepID: string): Blueprint => {
  const path = getStepTraversalPathForStepID(blueprint, stepID);
  if (!path) {
    return blueprint;
  }

  const childIndex = path[path.length - 1] as number;
  const parentPath = path.slice(0, -1);
  const childrenArray = getForTraversalPath(blueprint, parentPath);
  childrenArray.splice(childIndex, 1);

  const modifiedBlueprint = immutable.wrap(blueprint);
  modifiedBlueprint.set(parentPath, childrenArray);

  const stepNoteIndex = blueprint.step_notes.findIndex((stepNote) => stepNote.step_id === stepID);
  if (stepNoteIndex !== -1) {
    modifiedBlueprint.del(["step_notes", stepNoteIndex]);
  }

  return modifiedBlueprint.value();
};
export const deleteSteps = (blueprint: Blueprint, steps: Array<BlueprintStep>): Blueprint => {
  let newBlueprint = blueprint;
  steps.forEach((step) => (newBlueprint = deleteStep(newBlueprint, step.id)));
  return newBlueprint;
};

export const deleteIfElseStepAndMoveChildrenUp = (
  blueprint: Blueprint,
  step: BlueprintIfElseStep
): Blueprint => {
  // Should only be called on an IfElse step and should preserve substeps, with substeps in the 'true' path first.
  const path = getStepTraversalPathForStepID(blueprint, step.id) as TraversalPath;
  const parentPath = path.slice(0, -1);
  const stepIndex = path[path.length - 1] as number;
  const paths = step.paths as BlueprintStepPaths<any>;
  const trueSteps = paths["true"] ?? []; //@ts-ignore
  const falseSteps = paths["false"] ?? []; //@ts-ignore
  const stepsArray = getForTraversalPath(blueprint, parentPath);
  const newSteps = [
    ...stepsArray.slice(0, stepIndex),
    ...trueSteps,
    ...falseSteps,
    ...stepsArray.slice(stepIndex + 1),
  ];
  const newBlueprint = immutable.set(blueprint, parentPath, newSteps);
  return newBlueprint;
};

export const collapseSubsteps = (blueprint: Blueprint, stepID: string): Blueprint => {
  const path = getStepTraversalPathForStepID(blueprint, stepID);
  if (!path) {
    return blueprint;
  }

  const step = getStepForStepID(blueprint, stepID);
  if (!step) {
    return blueprint;
  }

  const updatedStep = { ...step };
  updatedStep.hasCollapsedSubsteps = !step.hasCollapsedSubsteps;

  blueprint = immutable.set(blueprint, path, updatedStep);

  const substep_ids = getAllStepIDsInBranch(step as BlueprintStep);
  for (let key in substep_ids) {
    blueprint = markSubstepCollapsed(blueprint, substep_ids[key]);
  }

  return blueprint;
};

export const markSubstepCollapsed = (blueprint: Blueprint, stepID: string): Blueprint => {
  const substep = getStepForStepID(blueprint, stepID);
  if (!substep) {
    return blueprint;
  }

  const substep_path = getStepTraversalPathForStepID(blueprint, stepID);
  if (!substep_path) {
    return blueprint;
  }
  const updatedSubstep = { ...substep };
  updatedSubstep.isCollapsed = !updatedSubstep.isCollapsed;
  return immutable.set(blueprint, substep_path, updatedSubstep);
};

export const getIDPrefixForGenericStepTemplate = (stepTemplate: BlueprintGenericStepTemplate) => {
  const commonModelSuffix = stepTemplate.metadata.common_model?.name ?? "";
  const prefix = stepTemplate.name.replaceAll(" ", "").toLowerCase();
  return `${prefix}${commonModelSuffix}`;
};

// Straightforward.
export const getIDPrefixForStepTemplate = (stepTemplate: BlueprintStepTemplate) => {
  switch (stepTemplate.step_type) {
    case BlueprintStepType.APIRequest:
      return "apirequest";
    case BlueprintStepType.APIRequestLoop:
      return "apirequestloop";
    case BlueprintStepType.APIRequestProxy:
      return "apirequestproxy";
    case BlueprintStepType.APIRequestLive:
      return "apiliverequest";
    case BlueprintStepType.IfElse:
      return "ifelse";
    case BlueprintStepType.ArrayLoop:
      return "loop";
    case BlueprintStepType.WhileLoop:
      return "whileloop";
    case BlueprintStepType.DateRangeLoop:
      return "daterangeloop";
    case BlueprintStepType.TraverseTree:
      return "traversetree";
    case BlueprintStepType.Switch:
      return "switch";
    case BlueprintStepType.AddParamToEndpoint:
      return "addparamtoendpoint";
    case BlueprintStepType.CustomFunction:
      return "customfunction";
    case BlueprintStepType.CreateOrUpdate:
      return `createorupdate${stepTemplate?.metadata?.common_model?.name}`;
    case BlueprintStepType.UpdateByModelID:
      return `updatebymodelID${stepTemplate?.metadata?.common_model?.name}`;
    case BlueprintStepType.InitializeWrittenCommonModel:
      return `initializewrittencommonmodel${stepTemplate?.metadata?.common_model?.name}`;
    case BlueprintStepType.GetOrCreateByRemoteId:
      return `getorcreatewithremoteid${stepTemplate?.metadata?.common_model?.name}`;
    case BlueprintStepType.GetById:
      return `getbyid${stepTemplate?.metadata?.common_model?.name}`;
    case BlueprintStepType.GetRelationById:
      return `getrelationbyid${stepTemplate?.metadata?.common_model?.name}`;
    case BlueprintStepType.CommonModelLoop:
      return `commonmodelloop${stepTemplate?.metadata?.common_model?.name}`;
    case BlueprintStepType.DataTransform:
      return "datatransform";
    case BlueprintStepType.AddToArray:
      return "addtoarray";
    case BlueprintStepType.AddToDictionary:
      return "addtodictionary";
    case BlueprintStepType.SetVariable:
      return "setvariable";
    case BlueprintStepType.UpdateLinkedAccount:
      return "updatelinkedaccount";
    case BlueprintStepType.ParseFromRemoteData:
      return "parseFromRemoteData";
    case BlueprintStepType.GetLinkedAccountFields:
      return "getlinkedaccountfields";
    case BlueprintStepType.Assert:
      return "assert";
    case BlueprintStepType.RunBlueprint:
      return "runblueprint";
    case BlueprintStepType.FileToUrl:
      return "filetourl";
    case BlueprintStepType.RaiseException:
      return "raiseexception";
    case BlueprintStepType.EndBlueprint:
      return "endblueprint";
    case BlueprintStepType.GetFirstInList:
      return "getfirstinlist";
    case BlueprintStepType.FileUrlToFile:
      return "fileurltofile";
    case BlueprintStepType.ConcurrentRequestLoop:
      return "concurrentrequestloop";
    case BlueprintStepType.SetRemoteDeleted:
      return `setremotedeleted${stepTemplate?.metadata?.common_model?.name}`;
    case BlueprintStepType.AddAvailableCustomField:
      return `addavailablecustomfield${stepTemplate?.metadata?.common_model?.name}`;
    case BlueprintStepType.FetchCustomFieldMapping:
      return `fetchcustomfieldmapping${stepTemplate.metadata.common_model.name}`;
    case BlueprintStepType.MetaAccessParentBPParamNames:
      return `parentbpparams`;
    case BlueprintStepType.MetaAddEnumChoiceToField:
      return "addenumchoicetofield";
    case BlueprintStepType.MetaAddLinkedAccountParam:
      return "addlinkedaccountparam";
    case BlueprintStepType.AddValidationProblem:
      return "addvalidationproblem";
    case BlueprintStepType.AddLinkedAccountAdditionalAuth:
      return "addadditionalauth";
    case BlueprintStepType.GetModifiedSinceTimestampValue:
      return "gettimestamp";
    case BlueprintStepType.CreateOAuth1Signature:
      return "createOAuth1Signature";
    case BlueprintStepType.BatchAPIRespnse:
      return "batchAPIResponse";
    case BlueprintStepType.CallFunctionalBP:
      return "callfunctionalbp";
    case BlueprintStepType.ReturnValuesToParentBP:
      return "returnvaluestoparentbp";
    case BlueprintStepType.MergeObjects:
      return "mergeobjects";
    case BlueprintStepType.WriteFile:
      return "writefile";
    case BlueprintStepType.ManyToManyOverride:
      return `manytomanyoverride${stepTemplate?.metadata?.common_model?.name}`;
    case BlueprintStepType.CreateQBXMLQuery:
      return "createqbxmlquery";
  }
};

// Returns the IDNumber for the step ID IDPrefix.IDNumber, given by incrementing the highest step ID inside
// the blueprint
export const getNextIDNumberForStepTemplateAndPrefix = (
  blueprint: Blueprint,
  stepTemplate: BlueprintStepTemplate | BlueprintGenericStepTemplate,
  stepIDPrefix: string
): number => {
  let maxID = 0;

  const stepTraversalHelper = (steps: Array<BlueprintStep>): void => {
    for (const [_, step] of steps.entries()) {
      if (
        step.id.startsWith(stepIDPrefix) &&
        !(
          stepTemplate.step_type === BlueprintStepType.APIRequest &&
          step.id.startsWith("apirequestloop")
        )
      ) {
        const IDVal = parseInt(step.id.replace(stepIDPrefix, "").replace(/\D/g, ""));
        if (Number.isInteger(IDVal)) maxID = Math.max(maxID, IDVal);
      }
      const paths = step.paths;
      if (paths != null) {
        for (const [_, substeps] of Object.entries(paths)) {
          stepTraversalHelper(substeps);
        }
      }
    }
  };

  stepTraversalHelper(blueprint.steps);
  return maxID + 1;
};

export const getNextIDNumberForStepTemplate = (
  blueprint: Blueprint,
  stepTemplate: BlueprintStepTemplate
): number => {
  const stepIDPrefix = getIDPrefixForStepTemplate(stepTemplate);
  return getNextIDNumberForStepTemplateAndPrefix(blueprint, stepTemplate, stepIDPrefix);
};

export const getNextIDNumberForGenericStepTemplate = (
  blueprint: Blueprint,
  stepTemplate: BlueprintGenericStepTemplate
): number => {
  const stepIDPrefix = getIDPrefixForGenericStepTemplate(stepTemplate);
  return getNextIDNumberForStepTemplateAndPrefix(blueprint, stepTemplate, stepIDPrefix);
};

export const initializePathsForStepType = (
  stepType: BlueprintStepType
): undefined | BlueprintStepPaths<any> => {
  switch (stepType) {
    case BlueprintStepType.APIRequest:
    case BlueprintStepType.APIRequestProxy:
    case BlueprintStepType.APIRequestLive:
    case BlueprintStepType.CreateOrUpdate:
    case BlueprintStepType.UpdateByModelID:
    case BlueprintStepType.InitializeWrittenCommonModel:
    case BlueprintStepType.GetOrCreateByRemoteId:
    case BlueprintStepType.AddParamToEndpoint:
    case BlueprintStepType.GetById:
    case BlueprintStepType.GetRelationById:
    case BlueprintStepType.SetRemoteDeleted:
    case BlueprintStepType.CustomFunction:
    case BlueprintStepType.DataTransform:
    case BlueprintStepType.AddToArray:
    case BlueprintStepType.AddToDictionary:
    case BlueprintStepType.SetVariable:
    case BlueprintStepType.UpdateLinkedAccount:
    case BlueprintStepType.ParseFromRemoteData:
    case BlueprintStepType.GetLinkedAccountFields:
    case BlueprintStepType.Assert:
    case BlueprintStepType.RunBlueprint:
    case BlueprintStepType.FileToUrl:
    case BlueprintStepType.RaiseException:
    case BlueprintStepType.EndBlueprint:
    case BlueprintStepType.FileUrlToFile:
    case BlueprintStepType.AddAvailableCustomField:
    case BlueprintStepType.FetchCustomFieldMapping:
    case BlueprintStepType.MetaAddEnumChoiceToField:
    case BlueprintStepType.MetaAddLinkedAccountParam:
    case BlueprintStepType.MetaAccessParentBPParamNames:
    case BlueprintStepType.AddValidationProblem:
    case BlueprintStepType.AddLinkedAccountAdditionalAuth:
    case BlueprintStepType.GetModifiedSinceTimestampValue:
    case BlueprintStepType.CreateOAuth1Signature:
    case BlueprintStepType.GetFirstInList:
    case BlueprintStepType.CallFunctionalBP:
    case BlueprintStepType.ReturnValuesToParentBP:
    case BlueprintStepType.MergeObjects:
    case BlueprintStepType.WriteFile:
    case BlueprintStepType.ManyToManyOverride:
    case BlueprintStepType.CreateQBXMLQuery:
      return undefined;
    case BlueprintStepType.ArrayLoop:
    case BlueprintStepType.WhileLoop:
    case BlueprintStepType.BatchAPIRespnse:
    case BlueprintStepType.CommonModelLoop:
    case BlueprintStepType.APIRequestLoop:
    case BlueprintStepType.TraverseTree:
    case BlueprintStepType.DateRangeLoop:
    case BlueprintStepType.ConcurrentRequestLoop:
      return { true: [] };
    case BlueprintStepType.IfElse:
      return { true: [], false: [] };
    case BlueprintStepType.Switch:
      return {};
  }
};

export const initializeParameterValuesForStepType = (
  stepType: BlueprintStepType
): Record<string, BlueprintParameterValue> => {
  switch (stepType) {
    case BlueprintStepType.APIRequest:
    case BlueprintStepType.APIRequestProxy:
    case BlueprintStepType.APIRequestLive:
    case BlueprintStepType.AddParamToEndpoint:
    case BlueprintStepType.APIRequestLoop:
    case BlueprintStepType.TraverseTree:
    case BlueprintStepType.CreateOrUpdate:
    case BlueprintStepType.UpdateByModelID:
    case BlueprintStepType.InitializeWrittenCommonModel:
    case BlueprintStepType.SetRemoteDeleted:
    case BlueprintStepType.GetOrCreateByRemoteId:
    case BlueprintStepType.GetById:
    case BlueprintStepType.GetRelationById:
    case BlueprintStepType.CommonModelLoop:
    case BlueprintStepType.ArrayLoop:
    case BlueprintStepType.WhileLoop:
    case BlueprintStepType.DateRangeLoop:
    case BlueprintStepType.SetVariable:
    case BlueprintStepType.UpdateLinkedAccount:
    case BlueprintStepType.GetLinkedAccountFields:
    case BlueprintStepType.RunBlueprint:
    case BlueprintStepType.FileToUrl:
    case BlueprintStepType.FileUrlToFile:
    case BlueprintStepType.ConcurrentRequestLoop:
    case BlueprintStepType.AddAvailableCustomField:
    case BlueprintStepType.FetchCustomFieldMapping:
    case BlueprintStepType.MetaAddEnumChoiceToField:
    case BlueprintStepType.MetaAddLinkedAccountParam:
    case BlueprintStepType.MetaAccessParentBPParamNames:
    case BlueprintStepType.AddValidationProblem:
    case BlueprintStepType.GetModifiedSinceTimestampValue:
    case BlueprintStepType.AddLinkedAccountAdditionalAuth:
    case BlueprintStepType.CreateOAuth1Signature:
    case BlueprintStepType.BatchAPIRespnse:
    case BlueprintStepType.EndBlueprint:
    case BlueprintStepType.GetFirstInList:
    case BlueprintStepType.CallFunctionalBP:
    case BlueprintStepType.ReturnValuesToParentBP:
    case BlueprintStepType.MergeObjects:
    case BlueprintStepType.WriteFile:
    case BlueprintStepType.ManyToManyOverride:
    case BlueprintStepType.CreateQBXMLQuery:
      return {};
    case BlueprintStepType.IfElse:
      return {
        logical_operator: {
          constant: BlueprintLogicalOperator.ALL,
          value_type: BlueprintParameterValueType.constant,
        },
        statements: {
          value_type: BlueprintParameterValueType.statementArray,
          statement_array: [
            {
              id: "statement0",
              val1: null,
              val2: null,
              comparator: null,
            },
          ],
        },
      };
    case BlueprintStepType.Switch:
      return {
        options: {
          constant: [],
          value_type: BlueprintParameterValueType.constant,
        },
      };
    case BlueprintStepType.CustomFunction:
      return {
        code: {
          constant: "",
          value_type: BlueprintParameterValueType.constant,
        },
        arguments: {
          nested_parameter_values: {},
          value_type: BlueprintParameterValueType.nestedParameterValues,
        },
      };
    case BlueprintStepType.RaiseException:
      return {
        exception_text: {
          constant: "",
          value_type: BlueprintParameterValueType.constant,
        },
        parameters: {
          nested_parameter_values: {},
          value_type: BlueprintParameterValueType.nestedParameterValues,
        },
      };
    case BlueprintStepType.ParseFromRemoteData:
      return {};
    case BlueprintStepType.DataTransform:
      return {
        procedures: {
          value_type: BlueprintParameterValueType.procedureArray,
          procedure_array: [],
        },
      };
    case BlueprintStepType.AddToArray:
      return {
        array: {
          value_type: BlueprintParameterValueType.constant,
          constant: "",
        },
        values: {
          value_type: BlueprintParameterValueType.customArray,
          array_values: [
            {
              value_type: BlueprintParameterValueType.constant,
              constant: "",
            },
          ],
        },
      };
    case BlueprintStepType.AddToDictionary:
      return {
        dictionary: {
          value_type: BlueprintParameterValueType.constant,
          constant: "",
        },
        items: {
          value_type: BlueprintParameterValueType.customArray,
          array_values: [
            {
              value_type: BlueprintParameterValueType.nestedParameterValues,
              nested_parameter_values: {
                key: {
                  value_type: BlueprintParameterValueType.constant,
                  constant: "",
                },
                value: {
                  value_type: BlueprintParameterValueType.constant,
                  constant: "",
                },
              },
            },
          ],
        },
      };
    case BlueprintStepType.Assert:
      return {
        comparator: {
          constant: BlueprintComparator.EQUAL,
          value_type: BlueprintParameterValueType.constant,
        },
        parameters: {
          nested_parameter_values: {
            value1: {
              constant: "",
              value_type: BlueprintParameterValueType.constant,
            },
            value2: {
              constant: "",
              value_type: BlueprintParameterValueType.constant,
            },
          },
          value_type: BlueprintParameterValueType.nestedParameterValues,
        },
      };
  }
};

export const doesBlueprintHaveExistingPaginatedLoop = (blueprint: Blueprint): boolean =>
  getAllStepsForBlueprint(blueprint).some((step) => step?.use_pagination_timestamp);

export const markTimestampsOnIfFirstPaginatedLoopInBlueprint = (
  blueprint: Blueprint,
  stepType: BlueprintStepType
): { use_pagination_timestamp?: boolean } => {
  return stepType === BlueprintStepType.APIRequestLoop
    ? { use_pagination_timestamp: !doesBlueprintHaveExistingPaginatedLoop(blueprint) }
    : {};
};

// given a template, gens and adds an id field based on template step type, sets up other fields
export const initializeStepFromStepTemplate = <T extends BlueprintStepType>(
  blueprint: Blueprint,
  template: BlueprintStepTemplateBase<T, any>
): BlueprintStepBase<T, string, any> => {
  const stepIDPrefix = getIDPrefixForStepTemplate(template);
  const stepIDNumber = getNextIDNumberForStepTemplate(blueprint, template);
  const newStepID = `${stepIDPrefix}${stepIDNumber}`;
  const paths = initializePathsForStepType(template.step_type);

  const parameter_values = initializeParameterValuesForStepType(template.step_type);

  return {
    id: newStepID,
    parameter_values,
    paths,
    // @ts-ignore
    template,
    ...markTimestampsOnIfFirstPaginatedLoopInBlueprint(blueprint, template.step_type),
  };
};

// given a template, gens and adds an id field based on template step type, sets up other fields
export const initializeGenericStepFromStepTemplate = (
  blueprint: Blueprint,
  template: BlueprintGenericStepTemplate
): BlueprintStepBase<null, string, any> => {
  const stepIDPrefix = getIDPrefixForGenericStepTemplate(template);
  const stepIDNumber = getNextIDNumberForGenericStepTemplate(blueprint, template);
  const newStepID = `${stepIDPrefix}${stepIDNumber}`;
  // We'll need to add these to the config when we migrate existing steps.
  const paths = undefined;
  const parameter_values = {};

  return {
    id: newStepID,
    parameter_values,
    paths,
    template,
  };
};

export const addNewStep = (
  blueprint: Blueprint,
  newStep: BlueprintStep,
  siblingStepID: string,
  siblingStepRelation: AddStepRelation,
  pathKey?: undefined | string
): { blueprint: Blueprint; step: BlueprintStep } => {
  switch (siblingStepRelation) {
    case AddStepRelation.SIBLING_BEFORE:
    case AddStepRelation.SIBLING_AFTER:
      return addSiblingStep(blueprint, newStep, siblingStepID, siblingStepRelation);
    case AddStepRelation.CHILD:
      return addChildStep(blueprint, newStep, siblingStepID, pathKey);
    case AddStepRelation.EMPTY_BLUEPRINT:
      return addFirstStepInBlueprint(blueprint, newStep);
  }
};

export const getAllStepIDsInBranch = (
  step?: BlueprintStepOrGhostStepOrTriggerOrScraper
): Array<string> => {
  if (!isBlueprintStep(step)) {
    return [];
  }
  let IDs: Array<string> = [];

  const stepTraversalHelper = (step: BlueprintStep) => {
    IDs.push(step.id);
    if (step.paths != null) {
      for (const [_, substeps] of Object.entries(step.paths)) {
        substeps.forEach((substep) => stepTraversalHelper(substep));
      }
    }
  };

  stepTraversalHelper(step);

  return IDs;
};

export const getParentStepToIDList = (
  steps?: Array<BlueprintStep>
): { [key: string]: string[] } => {
  let parentToIDList: { [key: string]: string[] } = {};
  if (!steps || steps.length === 0) {
    return parentToIDList;
  }

  const stepTraversalHelper = (step: BlueprintStep) => {
    let subStepIDs: string[] = [];
    if (step.paths != null) {
      for (const [_, substeps] of Object.entries(step.paths)) {
        substeps.forEach((substep) => subStepIDs.push(...stepTraversalHelper(substep)));
      }
    }
    parentToIDList[step.id] = subStepIDs;
    return [step.id, ...subStepIDs];
  };

  for (const step of steps) {
    stepTraversalHelper(step);
  }
  return parentToIDList;
};

export const getAllCollapsedStepIDs = (blueprint: Blueprint): Array<string> => {
  let collapsed_steps = getAllStepsForBlueprintOrScraper(blueprint).filter(
    (step) => step.isCollapsed
  );

  return collapsed_steps.map(({ id }) => id);
};

export const getAllTerminalStepsFromStep = (
  blueprint: Blueprint | ScraperVersion,
  step: BlueprintStep | ScraperStep | null
) => {
  if (step === null) {
    return;
  }
  let terminalSteps: Array<BlueprintStep | ScraperStep> = [];
  let predecessorStep: BlueprintStep | ScraperStep | null;

  const stepTraversalHelper = (step: BlueprintStep | ScraperStep) => {
    predecessorStep = getPredecessorStepForStep(blueprint, step);

    if (predecessorStep != null && terminalSteps.includes(predecessorStep)) {
      terminalSteps = terminalSteps.filter((step) => step !== predecessorStep);
    }

    if (predecessorStep != null && isStepTypeWithPaths(predecessorStep)) {
      terminalSteps = [];
    }

    if (step.paths === undefined || step.paths === null) {
      terminalSteps.push(step);
    }

    // if a step has collapsed substeps, it is terminal and its substeps are not
    if (step.hasCollapsedSubsteps) {
      terminalSteps.push(step);
      return;
    }

    if (step.paths != null) {
      for (const [_, substeps] of Object.entries(step.paths)) {
        substeps.forEach((substep: BlueprintStep | ScraperStep) => stepTraversalHelper(substep));
      }
    }
  };

  stepTraversalHelper(step);
  return terminalSteps;
};

const getAllStepIDsInBlueprint = (blueprint: Blueprint) => {
  const IDs: Set<string> = new Set();
  const stepTraversalHelper = (step: BlueprintStep) => {
    IDs.add(step.id);
    if (step.paths != null) {
      for (const [_, substeps] of Object.entries(step.paths)) {
        substeps.forEach((substep) => stepTraversalHelper(substep));
      }
    }
  };
  for (const [_, step] of Object.entries(blueprint.steps)) {
    if (isBlueprintStep(step)) stepTraversalHelper(step);
  }

  return IDs;
};

export const addNewCopiedStep = (
  blueprint: Blueprint,
  copiedStep: BlueprintStep,
  siblingStepID: string,
  siblingStepRelation: AddStepRelation,
  pathKey?: undefined | string
): { blueprint: Blueprint; step: BlueprintStep } => {
  // increment step ids, clear out param values

  const newStep = cloneDeep(copiedStep);
  if (siblingStepRelation === AddStepRelation.EMPTY_BLUEPRINT) {
    return addFirstStepInBlueprint(blueprint, newStep);
  }

  const originalStepIDs = getAllStepIDsInBranch(copiedStep);
  const siblingTraversalPath = getStepTraversalPathForStepID(blueprint, siblingStepID) as Array<
    string | number
  >;
  const newBranchTraversalPath = [
    ...siblingTraversalPath.slice(0, -1),
    (siblingTraversalPath[siblingTraversalPath.length - 1] as number) +
      (siblingStepRelation === AddStepRelation.SIBLING_AFTER ? 1 : 0),
  ];
  newBranchTraversalPath.shift();
  const allStepIDs = getAllStepIDsInBlueprint(blueprint);
  const createdIDs = new Set();
  const stepTraversalHelper = (steps: Array<BlueprintStep>): void => {
    for (const [_, step] of steps.entries()) {
      while (allStepIDs.has(step.id) || createdIDs.has(step.id)) {
        step.id = step.id + "x";
      }
      createdIDs.add(step.id);
      const paths = { ...step.paths };

      const recursiveParamCopyHelper = (parameterValues: {
        [key: string]: null | BlueprintParameterValue;
      }): { [key: string]: BlueprintParameterValue } => {
        return Object.fromEntries(
          Object.entries(parameterValues).map(([key, parameterValue]) => {
            switch (parameterValue?.value_type) {
              case BlueprintParameterValueType.returnValue:
                const paramStepID = parameterValue.step_id;
                const isParamFromCopiedStepTree = originalStepIDs.includes(paramStepID);
                if (!isParamFromCopiedStepTree) {
                  // we save remote_data_path in a funky way, this is to handle copy pasting remote_data_path params
                  if (paramStepID.includes("remote_data_path")) {
                    const actualStepID = paramStepID.split("remote_data_path")[0];
                    return [
                      key,
                      {
                        ...parameterValue,
                        step_id: allStepIDs.has(actualStepID)
                          ? actualStepID + "xremote_data_path"
                          : paramStepID,
                      } as BlueprintParameterValueReturnValue,
                    ];
                  }
                  const paramStep = getStepForStepID(blueprint, paramStepID) as BlueprintStep;
                  if (paramStep === null) {
                    return [key, parameterValue];
                  }
                  const canUseParam = areParametersForBlueprintStepAvailableToCurrentStep(
                    blueprint,
                    [...newBranchTraversalPath],
                    paramStep
                  );
                  return [key, canUseParam ? parameterValue : undefined];
                }
                return [
                  key,
                  {
                    ...parameterValue,
                    step_id: allStepIDs.has(paramStepID) ? paramStepID + "x" : paramStepID,
                  } as BlueprintParameterValueReturnValue,
                ];
              case BlueprintParameterValueType.nestedParameterValues:
                const copiedNestedParameterValues = recursiveParamCopyHelper(
                  parameterValue.nested_parameter_values
                );
                return [
                  key,
                  {
                    ...parameterValue,
                    nested_parameter_values: copiedNestedParameterValues,
                  },
                ];
              case BlueprintParameterValueType.procedureArray:
                //TODO Henry: This is some hella complicated logic that we shouldn't address unless it shows up a good amount
                const copiedProcedureArray = parameterValue.procedure_array.map(
                  (procedure: BlueprintProcedure) => {
                    procedure.parameter_values = recursiveParamCopyHelper(
                      procedure.parameter_values
                    );
                    return procedure;
                  }
                );
                return [key, { ...parameterValue, procedure_array: copiedProcedureArray }];
              case BlueprintParameterValueType.statementArray:
                const copiedStatementArray = parameterValue.statement_array.map(
                  (statement: BlueprintLogicalOperation) => {
                    statement.val1 = statement.val1
                      ? recursiveParamCopyHelper({ _: statement.val1 })["_"]
                      : statement.val1;
                    statement.val2 = statement.val2
                      ? recursiveParamCopyHelper({ _: statement.val2 })["_"]
                      : statement.val2;
                    return statement;
                  }
                );
                return [key, { ...parameterValue, statement_array: copiedStatementArray }];
              default:
                return [key, parameterValue];
            }
          })
        );
      };
      const copiedParamValues = recursiveParamCopyHelper(step.parameter_values);

      step.parameter_values = copiedParamValues;
      if (paths != null) {
        for (const [_, substeps] of Object.entries(paths)) {
          const castedSubsteps = substeps as Array<BlueprintStep>;
          stepTraversalHelper(castedSubsteps);
        }
      }
    }
  };
  stepTraversalHelper([newStep]);

  return addNewStep(blueprint, newStep, siblingStepID, siblingStepRelation, pathKey);
};

export const addNewStepFromTemplate = <T extends BlueprintStepType>(
  blueprint: Blueprint,
  template: BlueprintStepTemplateBase<T, any>,
  siblingStepID: string,
  siblingStepRelation: AddStepRelation,
  pathKey?: undefined | string
): { blueprint: Blueprint; step: BlueprintStep } => {
  const newStep = initializeStepFromStepTemplate(blueprint, template);

  return addNewStep(blueprint, newStep, siblingStepID, siblingStepRelation, pathKey);
};

export const addNewGenericStepFromTemplate = (
  blueprint: Blueprint,
  template: BlueprintGenericStepTemplate,
  siblingStepID: string,
  siblingStepRelation: AddStepRelation,
  pathKey?: undefined | string
): { blueprint: Blueprint; step: BlueprintStep } => {
  const newStep = initializeGenericStepFromStepTemplate(blueprint, template);

  return addNewStep(blueprint, newStep, siblingStepID, siblingStepRelation, pathKey);
};

export const renameBlueprintStep = (
  blueprint: Blueprint,
  stepID: string,
  newID: string
): { blueprint: Blueprint; step: BlueprintStep } | null => {
  const stepTraversalHelper = (
    steps: Array<BlueprintStep>,
    stepID: string,
    newID: string
  ): boolean => {
    for (const step of steps.values()) {
      if (step.id === newID) {
        return false;
      } else if (step.id === stepID) {
        step.id = newID;
      } else if (
        step.template.step_type == BlueprintStepType.DataTransform &&
        step.parameter_values.procedures.value_type === BlueprintParameterValueType.procedureArray
      ) {
        let procedures = step.parameter_values.procedures.procedure_array;

        procedures.map((procedure, index) => {
          procedures[index].parameter_values = Object.fromEntries(
            Object.entries(procedure.parameter_values).map(([key, parameterValue]) => {
              const newParameterValue = parameterValue
                ? parameterValue.value_type === BlueprintParameterValueType.returnValue &&
                  parameterValue.step_id == stepID
                  ? { ...parameterValue, step_id: newID }
                  : { ...parameterValue }
                : null;

              return [key, newParameterValue];
            })
          );
        });
      } else if (
        step.template.step_type == BlueprintStepType.CustomFunction &&
        step.parameter_values.arguments.value_type ===
          BlueprintParameterValueType.nestedParameterValues
      ) {
        let parameter_values = step.parameter_values.arguments.nested_parameter_values;
        step.parameter_values.arguments.nested_parameter_values = Object.fromEntries(
          Object.entries(parameter_values).map(([key, parameterValue]) => {
            const newParameterValue =
              parameterValue.value_type === BlueprintParameterValueType.returnValue &&
              parameterValue.step_id == stepID
                ? { ...parameterValue, step_id: newID }
                : { ...parameterValue };
            return [key, newParameterValue];
          })
        );
      } else if (
        step.template.step_type == BlueprintStepType.Assert &&
        step.parameter_values.parameters.value_type ===
          BlueprintParameterValueType.nestedParameterValues
      ) {
        let parameter_values = step.parameter_values.parameters.nested_parameter_values;
        step.parameter_values.parameters.nested_parameter_values = Object.fromEntries(
          Object.entries(parameter_values).map(([key, parameterValue]) => {
            const newParameterValue =
              parameterValue.value_type === BlueprintParameterValueType.returnValue &&
              parameterValue.step_id == stepID
                ? { ...parameterValue, step_id: newID }
                : { ...parameterValue };
            return [key, newParameterValue];
          })
        );
      } else {
        step.parameter_values = Object.fromEntries(
          Object.entries(step.parameter_values).map(([key, parameterValue]) => {
            const newParameterValue =
              parameterValue.value_type === BlueprintParameterValueType.returnValue &&
              parameterValue.step_id == stepID
                ? { ...parameterValue, step_id: newID }
                : { ...parameterValue };

            return [key, newParameterValue];
          })
        );
      }

      const paths = { ...step.paths };

      if (paths != null) {
        for (const [_, substeps] of Object.entries(paths)) {
          if (!stepTraversalHelper(substeps, stepID, newID)) {
            return false;
          }
        }
      }
    }

    return true;
  };

  const newBlueprint = cloneDeep(blueprint);

  const successfulTraversal = stepTraversalHelper(newBlueprint.steps, stepID, newID);

  if (!successfulTraversal) {
    return null;
  }

  const stepNote = newBlueprint.step_notes.find((stepNote) => stepNote.step_id === stepID);
  if (stepNote) {
    stepNote.step_id = newID;
  }

  const newStep = getStepForStepID(newBlueprint, newID) as BlueprintStep;
  return { blueprint: newBlueprint, step: newStep };
};

export const updateBlueprintStepConcurrency = (
  blueprint: Blueprint,
  stepID: string,
  runConcurrently: boolean
): { blueprint: Blueprint; step: BlueprintStep } => {
  const findAndUpdateStep = (
    steps: Array<BlueprintStep>,
    stepID: string,
    runConcurrently: boolean
  ): void => {
    for (const step of steps.values()) {
      if (step.id === stepID) {
        step.run_concurrently = runConcurrently;
        return;
      }
      const paths = { ...step.paths };

      if (paths != null) {
        for (const substeps of Object.values(paths)) {
          const castedSubsteps = substeps as Array<BlueprintStep>;
          findAndUpdateStep(castedSubsteps, stepID, runConcurrently);
        }
      }
    }
  };

  const newBlueprint = cloneDeep(blueprint);
  findAndUpdateStep(newBlueprint.steps, stepID, runConcurrently);
  const newStep = getStepForStepID(newBlueprint, stepID) as BlueprintStep;

  return { blueprint: newBlueprint, step: newStep };
};

export const updateUsePaginationTimestamp = (
  blueprint: Blueprint,
  stepID: string,
  usePaginationTimestamp: boolean
): { blueprint: Blueprint; step: BlueprintStep } => {
  const findAndUpdateStep = (
    steps: Array<BlueprintStep>,
    stepID: string,
    usePaginationTimestamp: boolean
  ): void => {
    for (const step of steps.values()) {
      if (step.id === stepID) {
        step.use_pagination_timestamp = usePaginationTimestamp;
        return;
      }
      const paths = { ...step.paths };

      if (paths != null) {
        for (const substeps of Object.values(paths)) {
          const castedSubsteps = substeps as Array<BlueprintStep>;
          findAndUpdateStep(castedSubsteps, stepID, usePaginationTimestamp);
        }
      }
    }
  };

  const newBlueprint = cloneDeep(blueprint);
  findAndUpdateStep(newBlueprint.steps, stepID, usePaginationTimestamp);
  const newStep = getStepForStepID(newBlueprint, stepID) as BlueprintStep;

  return { blueprint: newBlueprint, step: newStep };
};

export const updateClosesPaginationSequence = (
  blueprint: Blueprint,
  stepID: string,
  closesPaginationSequence: boolean
): { blueprint: Blueprint; step: BlueprintStep } => {
  const findAndUpdateStep = (
    steps: Array<BlueprintStep>,
    stepID: string,
    closesPaginationSequence: boolean
  ): void => {
    for (const step of steps.values()) {
      if (step.id === stepID) {
        step.pagination_complete_on_end = closesPaginationSequence;
        return;
      }
      const paths = { ...step.paths };

      if (paths != null) {
        for (const substeps of Object.values(paths)) {
          const castedSubsteps = substeps as Array<BlueprintStep>;
          findAndUpdateStep(castedSubsteps, stepID, closesPaginationSequence);
        }
      }
    }
  };

  const newBlueprint = cloneDeep(blueprint);
  findAndUpdateStep(newBlueprint.steps, stepID, closesPaginationSequence);
  const newStep = getStepForStepID(newBlueprint, stepID) as BlueprintStep;

  return { blueprint: newBlueprint, step: newStep };
};

export const getCommonModelChoices = (
  blueprint: Blueprint
): {
  name: string;
  id: string;
}[] => {
  return getCommonModelChoicesForCategories(blueprint.integration.categories);
};

export const getCommonModelChoicesForCategories = (
  categories: Array<string>
): {
  name: string;
  id: string;
}[] => {
  const commonModels: { name: string; id: string }[] = [];
  for (const categoryString of categories) {
    const category = apiCategoryFromString(categoryString);
    category &&
      Object.keys(CATEGORY_COMMON_MODEL_TYPES[category]).forEach((commonModel) =>
        commonModels.push({
          name: snakeCaseToFirstLetterUpperCase(commonModel),
          id: formatCommonModelId(category, commonModel),
        })
      );
  }
  return commonModels;
};

export const getCategoryChoices = (
  categories: Array<string>
): {
  name: string;
  id: string;
}[] => {
  const categoryChoices: { name: string; id: string }[] = [];
  for (const categoryString of categories) {
    categoryChoices.push({
      name: categoryString,
      id: categoryString,
    });
  }
  return categoryChoices;
};

export const setParameterSchemaForBlueprint = (
  blueprint: Blueprint,
  updatedBlueprintParameterSchema: JSONObjectSchema
): { blueprint: Blueprint } => {
  return {
    blueprint: {
      ...blueprint,
      parameter_schema: updatedBlueprintParameterSchema,
      updated_parameter_schema_for_auto_update: undefined,
    },
  };
};

// This method is specifically for QuickBooks Desktop - it sets the config for the request that is sent in the sendRequestXML callback
export const setQBXMLQueryRequestFormatForBlueprint = (
  blueprint: Blueprint,
  updatedQBXMLRequestFormat: string
): { blueprint: Blueprint } => {
  return {
    blueprint: {
      ...blueprint,
      qbxml_query_request_format: updatedQBXMLRequestFormat,
    },
  };
};

export const setReportFilesForBlueprint = (
  blueprint: Blueprint,
  reportFiles: Array<ReportFile>
): { blueprint: Blueprint } => {
  return {
    blueprint: {
      ...blueprint,
      report_files: reportFiles,
    },
  };
};

export const setReturnSchemaForBlueprint = (
  blueprint: Blueprint,
  updatedBlueprintReturnSchema: JSONObjectSchema
): { blueprint: Blueprint } => {
  return {
    blueprint: {
      ...blueprint,
      return_schema: updatedBlueprintReturnSchema,
    },
  };
};

export const setHumanNameForBlueprint = (
  blueprint: Blueprint,
  human_name: string
): { blueprint: Blueprint } => {
  return {
    blueprint: {
      ...blueprint,
      human_name,
    },
  };
};

export const setStepNoteForBlueprint = (
  blueprint: Blueprint,
  stepID: string,
  text: string
): { blueprint: Blueprint } => {
  const step_notes: Array<StepNote> = JSON.parse(JSON.stringify(blueprint.step_notes));

  const currentNote = step_notes.find((note) => stepID === note.step_id);
  if (currentNote) {
    currentNote.text = text;
  } else {
    step_notes.push({
      step_id: stepID,
      text,
    });
  }

  return {
    blueprint: {
      ...blueprint,
      step_notes,
    },
  };
};

export const addFirstStepInBlueprint = (
  blueprint: Blueprint,
  newStep: BlueprintStep
): { blueprint: Blueprint; step: BlueprintStep } => {
  blueprint.steps.push(newStep);
  return { blueprint, step: newStep };
};

export const addSiblingStep = (
  blueprint: Blueprint,
  newStep: BlueprintStep,
  siblingStepID: string,
  siblingStepRelation: AddStepRelation
): { blueprint: Blueprint; step: BlueprintStep } => {
  const path = getEnforceStepTraversalPathForStepID(blueprint, siblingStepID);

  const childIndex = path[path.length - 1] as number;
  const parentPath = path.slice(0, -1);
  const childrenArray = getForTraversalPath(blueprint, parentPath);
  childrenArray.splice(
    childIndex + (siblingStepRelation === AddStepRelation.SIBLING_AFTER ? 1 : 0),
    0,
    newStep
  );

  const newBlueprint = immutable.set(blueprint, parentPath, childrenArray);

  return { blueprint: newBlueprint, step: newStep };
};

export const addSiblingGenericStep = (
  blueprint: Blueprint,
  newStep: BlueprintStep,
  siblingStepID: string,
  siblingStepRelation: AddStepRelation
): { blueprint: Blueprint; step: BlueprintStep } => {
  const path = getEnforceStepTraversalPathForStepID(blueprint, siblingStepID);

  const childIndex = path[path.length - 1] as number;
  const parentPath = path.slice(0, -1);
  const childrenArray = getForTraversalPath(blueprint, parentPath);
  childrenArray.splice(
    childIndex + (siblingStepRelation === AddStepRelation.SIBLING_AFTER ? 1 : 0),
    0,
    newStep
  );

  const newBlueprint = immutable.set(blueprint, parentPath, childrenArray);

  return { blueprint: newBlueprint, step: newStep };
};

// adds a new step as the first child step for a given path key and parent step ID.
export const addChildStep = (
  blueprint: Blueprint,
  newStep: BlueprintStep,
  parentStepID: string,
  pathKey: undefined | string
): { blueprint: Blueprint; step: BlueprintStep } => {
  const traversalPath = getEnforceStepTraversalPathForStepID(blueprint, parentStepID);

  const parentPath = [...traversalPath, "paths", pathKey ?? ""];

  const currentPathArray: Array<BlueprintStep> = getForTraversalPath(blueprint, parentPath) ?? [];
  currentPathArray.unshift(newStep);

  return {
    blueprint: immutable.set(blueprint, parentPath, currentPathArray),
    step: newStep,
  };
};

// updates and returns both the blueprint and step given an updated step
export const updateStep = (
  blueprint: Blueprint,
  step: BlueprintStep
): { blueprint: Blueprint; step: BlueprintStep } => {
  const path = getStepTraversalPathForStepID(blueprint, step.id);
  if (!path) {
    return { blueprint, step };
  }
  const newBlueprint = immutable.set(blueprint, path, step);

  return { blueprint: newBlueprint, step };
};

// Pull sub-object from blueprint represented by array of keys/indices
export const getForTraversalPath = (
  blueprint: Blueprint | ScraperVersion,
  traversalPath: Array<string | number>
): any => {
  let currentObject: any = blueprint;
  for (const key of traversalPath) {
    currentObject = currentObject[key];
  }

  return Array.isArray(currentObject) ? [...currentObject] : currentObject;
};

/**
 * @param blueprint
 * @param traversalPath
 * @returns true if check returns true for at least one step in the path
 */
export const checkConditionForTraversalPath = (
  blueprintOrScraper: Blueprint | ScraperVersion,
  traversalPath: Array<string | number>,
  check: (step: BlueprintStep | ScraperStep) => boolean
): boolean => {
  let currentObject: any = blueprintOrScraper;
  for (const key of traversalPath) {
    currentObject = currentObject[key];

    if (Array.isArray(currentObject)) {
      continue;
    }
    if (check(currentObject)) {
      return true;
    }
  }

  return false;
};

// updates multiple parameter values for step given parameter values object, returning both the blueprint and the step
export const updateBlueprintForNewParameterValues = (
  blueprint: Blueprint,
  step: BlueprintStep,
  parameterValues: { [key: string]: BlueprintParameterValue | null }
): { blueprint: Blueprint; step: BlueprintStep } => {
  let newStep = step;

  for (const valueKey in parameterValues) {
    let value: BlueprintParameterValue | null | undefined = parameterValues[valueKey];
    if (value !== null) {
      if (!value.value_type) {
        value = undefined;
      } else if (value.is_unique_identifier === false) {
        value = immutable.del(value, ["is_unique_identifier"]);
      }
    }
    newStep = immutable.set(newStep, ["parameter_values", valueKey], value);
  }
  return updateStep(blueprint, newStep);
};

// updates parameter value for step given parameter key/value, returning both the blueprint and the step
export const updateBlueprintForNewParameterValue = (
  blueprint: Blueprint,
  step: BlueprintStep,
  valueKey: string,
  value: any
): { blueprint: Blueprint; step: BlueprintStep } => {
  return updateBlueprintForNewParameterValues(blueprint, step, { [valueKey]: value });
};

export const updateBlueprintForNewMockResponseBody = (
  blueprint: Blueprint,
  step: BlueprintStep,
  key: string,
  value: string
): { blueprint: Blueprint; step: BlueprintStep } => {
  const stepCopy = JSON.parse(JSON.stringify(step));

  const newStep = stepCopy.mock_response_body
    ? {
        ...stepCopy,
        mock_response_body: { ...stepCopy.mock_response_body, [key]: value },
      }
    : { ...stepCopy, mock_response_body: { [key]: value } };

  return updateStep(blueprint, newStep);
};

export const convertAPIRequestToAPIRequestLoop = (
  blueprint: Blueprint,
  oldStep: BlueprintStep,
  newTemplate: BlueprintStepTemplate
): { blueprint: Blueprint; step: BlueprintStep } => {
  const oldPath = getStepTraversalPathForStepID(blueprint, oldStep.id);
  if (!oldPath) {
    showErrorToast(`Failed to update, step not found`);
    return { blueprint: blueprint, step: oldStep };
  }

  const oldStepID = oldStep.id;

  const nextLoopStep = getForTraversalPath(blueprint, [
    ...oldPath?.slice(0, -1),
    (oldPath[oldPath.length - 1] as number) + 1,
  ]);

  const blueprintWithoutOldAPIRequest = deleteStep(blueprint, oldStep.id);

  const newStep = {
    ...oldStep,
    id: nextLoopStep.id,
    paths: nextLoopStep.paths,
    template: newTemplate,
  };

  const newBlueprint = immutable.set(blueprintWithoutOldAPIRequest, oldPath, newStep);

  const result = renameBlueprintStep(newBlueprint, newStep.id, oldStepID + "_loop");
  if (!result) {
    showErrorToast(`Failed to update, step name already exist: ${oldStepID + "_loop"}`);
    return { blueprint: blueprint, step: oldStep };
  }
  return result;
};

export const updateBlueprintForNewStepTemplate = (
  blueprint: Blueprint,
  step: BlueprintStep,
  newTemplate: BlueprintStepTemplate | BlueprintGenericStepTemplate
): { blueprint: Blueprint; step: BlueprintStep } => {
  const stepCopy = JSON.parse(JSON.stringify(step));
  if (step.template.step_type === BlueprintStepType.IfElse) {
    let statements = step.parameter_values?.["statements"];
    let comparator = step.parameter_values?.["comparator"];
    let value1 = step.parameter_values?.["value1"];
    let value2 = step.parameter_values?.["value2"];
    if (!statements) {
      const newStep = {
        ...stepCopy,
        parameter_values: {
          value1: { value_type: BlueprintParameterValueType.none },
          value2: { value_type: BlueprintParameterValueType.none },
          comparator: { value_type: BlueprintParameterValueType.none },
          statements: {
            value_type: BlueprintParameterValueType.statementArray,
            statement_array: [
              {
                id: "statement0",
                val1: value1,
                val2: value2,
                comparator: comparator,
              },
            ],
          },
        },
        template: newTemplate,
      };
      return updateStep(blueprint, newStep);
    }
  }
  if (
    stepCopy.template.step_type === BlueprintStepType.APIRequest &&
    (newTemplate.step_type === BlueprintStepType.APIRequestLoop ||
      newTemplate.step_type === BlueprintStepType.ConcurrentRequestLoop)
  ) {
    //@ts-ignore
    return convertAPIRequestToAPIRequestLoop(blueprint, stepCopy, newTemplate);
  }
  const newStep = { ...stepCopy, template: newTemplate };
  return updateStep(blueprint, newStep);
};

// gives the current value for a parameter given the step and value key
export const getCurrentStepParameterValue = (
  step: BlueprintStep,
  valueKey: string
): null | BlueprintParameterValue => {
  return step.parameter_values[valueKey];
};

export interface BlueprintAvailableParameter {
  labelKey: string;
  parameterValue: BlueprintParameterValue;
  extraData: {
    title: string;
    description: string;
    valueType: string;
    deprecated: boolean;
    format?: string;
  };
  customOption?: boolean;
}

export const getAllStepsForBlueprint = (blueprint: Blueprint): BlueprintStep[] =>
  getAllStepsForBlueprintOrScraper(blueprint) as BlueprintStep[];

export const getAllLoopStepsForBlueprint = (blueprint: Blueprint): BlueprintStep[] =>
  getAllStepsForBlueprintOrScraper(blueprint).filter((step) =>
    isStepTypeALoop("template" in step ? step.template.step_type : step.step_type)
  ) as BlueprintStep[];

// Return an array containing every step in a given blueprint or scraper.
export const getAllStepsForBlueprintOrScraper = (
  blueprint: Blueprint | ScraperVersion
): (BlueprintStep | ScraperStep)[] => {
  const stepArray: (BlueprintStep | ScraperStep)[] = [];

  const traverseStepArrayAndAppendSteps = (steps: Array<BlueprintStep> | Array<ScraperStep>) =>
    steps.forEach((step: BlueprintStep | ScraperStep) => {
      stepArray.push(step);
      Object.values(step.paths ?? {}).forEach((path) => traverseStepArrayAndAppendSteps(path));
    });

  traverseStepArrayAndAppendSteps(blueprint.steps);

  return stepArray;
};

export const isStepTypeALoop = (stepType: BlueprintStepType | ScraperStepType | string): boolean =>
  ([
    BlueprintStepType.ArrayLoop,
    BlueprintStepType.WhileLoop,
    BlueprintStepType.DateRangeLoop,
    BlueprintStepType.APIRequestLoop,
    BlueprintStepType.CommonModelLoop,
    BlueprintStepType.BatchAPIRespnse,
    BlueprintStepType.TraverseTree,
    ScraperStepType.ARRAY_LOOP,
  ] as Array<BlueprintStepType | ScraperStepType | string>).includes(stepType);

const areParametersForBlueprintStepAvailableToCurrentStep = (
  blueprint: Blueprint,
  currentStepTraversalPath: TraversalPath,
  stepToCheck: BlueprintStep
): boolean =>
  areParametersForBlueprintOrScraperStepAvailableToCurrentStep(
    blueprint,
    currentStepTraversalPath,
    stepToCheck
  );

// Return whether the currentStep can access parameters in a given step to check. Current step either needs to be a descendant of the step to check, or come after the step to check in the same step array.
// If the step to check is a loop, the currentStep needs to be a descendant.
export const areParametersForBlueprintOrScraperStepAvailableToCurrentStep = (
  blueprint: Blueprint | ScraperVersion,
  currentStepTraversalPath: TraversalPath,
  stepToCheck: BlueprintStep | ScraperStep
): boolean => {
  const stepToCheckTraversalPath = getStepTraversalPathForStepID(blueprint, stepToCheck.id);

  if (!stepToCheckTraversalPath) {
    return false;
  }

  stepToCheckTraversalPath.shift();

  const isStepToCheckALoop = isStepTypeALoop(
    "template" in stepToCheck ? stepToCheck.template.step_type : stepToCheck.step_type
  );

  return areParametersForBlueprintStepAvailableToCurrentStepPath(
    currentStepTraversalPath,
    stepToCheckTraversalPath,
    isStepToCheckALoop
  );
};

const areParametersForBlueprintStepAvailableToCurrentStepPath = (
  currentStepTraversalPath: TraversalPath,
  stepToCheckTraversalPath: TraversalPath,
  isStepToCheckALoop: boolean
): boolean => {
  const stepTraversalHelper = (
    currentStepTraversalPath: TraversalPath,
    stepToCheckTraversalPath: TraversalPath,
    onlyAllowAncestors: boolean
  ): boolean => {
    const currentStepIndex = currentStepTraversalPath.shift() as number;
    const stepToCheckIndex = stepToCheckTraversalPath.shift() as number;

    // available if current step is after step to check, not available if before.
    if (currentStepIndex != stepToCheckIndex) {
      return currentStepIndex > stepToCheckIndex && !onlyAllowAncestors;
    }

    // current step is the ancestor of the step to check, not available.
    if (currentStepTraversalPath.length === 0) {
      return false;
    }

    // step to check is the ancestor of the current step, available.
    if (stepToCheckTraversalPath.length === 0) {
      return true;
    }

    // steps
    currentStepTraversalPath.shift();
    stepToCheckTraversalPath.shift();

    //paths
    const currentStepPath = currentStepTraversalPath.shift();
    const stepToCheckPath = stepToCheckTraversalPath.shift();

    // the steps are in sibling paths, not available.
    if (currentStepPath != stepToCheckPath) {
      return false;
    }

    // the steps are in the same path, recurse!
    return stepTraversalHelper(
      currentStepTraversalPath,
      stepToCheckTraversalPath,
      onlyAllowAncestors
    );
  };

  return stepTraversalHelper(
    currentStepTraversalPath,
    stepToCheckTraversalPath,
    isStepToCheckALoop
  );
};

export const getSchemaForParameterValue = (
  blueprint: Blueprint,
  parameterValue: null | BlueprintParameterValue,
  procedureArray?: Array<BlueprintProcedure>
): JSONSchemaValue => {
  if (!parameterValue || parameterValue.value_type != BlueprintParameterValueType.returnValue) {
    return { type: "any" };
  } else {
    const { step_id, return_schema_path } = parameterValue;

    if (step_id.startsWith("procedure") && procedureArray) {
      const properties: { [parameter: string]: JSONSchemaValue } =
        procedureArray.find((procedure) => procedure.id === step_id)?.parameter_schema
          ?.properties ?? {};

      return (
        (immutable.get(properties, return_schema_path) as any) ?? {
          type: "any",
        }
      );
    } else {
      const step = getStepForStepID(blueprint, step_id) as BlueprintStep;
      return (
        immutable.get(step?.template.return_schema, return_schema_path) ?? {
          type: "any",
        }
      );
    }
  }
};

const returnsCommonModelInstance = (step_type: BlueprintStepType | string) => {
  return ([
    BlueprintStepType.CreateOrUpdate,
    BlueprintStepType.UpdateByModelID,
    BlueprintStepType.InitializeWrittenCommonModel,
    BlueprintStepType.ManyToManyOverride,
    BlueprintStepType.GetOrCreateByRemoteId,
    BlueprintStepType.GetById,
    BlueprintStepType.GetRelationById,
  ] as Array<BlueprintStepType | string>).includes(step_type);
};

const stringishTypes = ["string", "uuid", "date", "datetime", "email", "url"];

// accounts for 'any' type as well as string-ish, like UUID, date, etc.
const doParameterTypesMatch = (parameterType: string, schemaType: string): boolean =>
  (parameterType === "uuid" && schemaType === "uuid") ||
  (parameterType === "uuid" && ["string", "any"].includes(schemaType)) ||
  (parameterType !== "uuid" &&
    (parameterType === "any" ||
      schemaType === "any" ||
      parameterType === schemaType ||
      (stringishTypes.includes(parameterType) && stringishTypes.includes(schemaType))));

export const recursivelyAppendParameters = (
  resultArray: Array<BlueprintAvailableParameter>,
  parameterType: string,
  stepID: string,
  jsonSchema: BlueprintParameterSchemaValue | null,
  traversalPath: Array<string>,
  pathForDisplay: Array<string>,
  key: string,
  isGlobalVar = false
) => {
  if (!jsonSchema) {
    return;
  }
  const properties =
    jsonSchema.type === "object" || jsonSchema.type === undefined ? jsonSchema.properties : {};
  const jsonSchemaType =
    jsonSchema.type === undefined && properties !== {} ? "object" : jsonSchema.type;

  if (
    doParameterTypesMatch(parameterType, jsonSchema?.format === "uuid" ? "uuid" : jsonSchema.type)
  ) {
    if (isGlobalVar) {
      resultArray.push({
        labelKey: `global.${convertTraversalPathToDotNotation([...pathForDisplay])}`,
        parameterValue: {
          value_type: BlueprintParameterValueType.globalVariable,
          return_schema_path: traversalPath,
          request_return_value_path: [...pathForDisplay],
          key,
          required: false,
        },
        extraData: {
          title: `global.${key}`,
          description: convertTraversalPathToDotNotation([...pathForDisplay]),
          valueType: "Global Var",
          deprecated: false,
        },
      });
    }
    //@ts-ignore relation not technically JSONSchema but we use it
    else if (jsonSchema.relation) {
      const idPathForDisplay = [
        ...pathForDisplay.slice(0, -1),
        `${pathForDisplay[pathForDisplay.length - 1]}`,
      ];
      const idTraversalPath = [
        ...traversalPath.slice(0, -1),
        `${traversalPath[traversalPath.length - 1]}`,
      ];

      resultArray.push({
        labelKey: convertTraversalPathToDotNotation([stepID, ...idPathForDisplay]),
        parameterValue: {
          step_id: stepID,
          return_schema_path: idTraversalPath,
          request_return_value_path: idPathForDisplay,
          value_type: BlueprintParameterValueType.returnValue,
        },
        extraData: {
          title: `${key}`,
          description: "ID of relation",
          valueType: "string",
          format: "uuid",
          deprecated: false,
        },
      });
    } else {
      resultArray.push({
        labelKey: convertTraversalPathToDotNotation([stepID, ...pathForDisplay]),
        parameterValue: {
          step_id: stepID,
          return_schema_path: traversalPath,
          request_return_value_path: pathForDisplay,
          value_type: BlueprintParameterValueType.returnValue,
        },
        extraData: {
          title: key,
          description: "",
          valueType: jsonSchemaType,
          deprecated: !!jsonSchema.deprecated,
          ...(jsonSchema.format && { format: jsonSchema.format }),
        },
      });
    }
  }

  // do not recurse into array
  if (jsonSchema.type === "array") {
    return;
  }

  const newTraversalPath = [...traversalPath, "properties"];

  for (const [key, jsonSchemaValue] of Object.entries(properties ?? {})) {
    recursivelyAppendParameters(
      resultArray,
      parameterType,
      stepID,
      //@ts-ignore
      jsonSchemaValue,
      [...newTraversalPath, key],
      [...pathForDisplay, key],
      key,
      isGlobalVar
    );
  }
};

export const recursivelyAppendAvailableParametersFromGlobalVarJSONSchema = ({
  key,
  jsonSchema,
  requestReturnValuePath,
  returnSchemaPath,
}: {
  key?: string;
  jsonSchema?: JSONSchema;
  requestReturnValuePath: Array<string>;
  returnSchemaPath: Array<string>;
}): Array<{
  key: string;
  requestReturnValuePath: Array<string>;
  returnSchemaPath: Array<string>;
  type?: string;
}> => {
  if (!jsonSchema) {
    return [];
  }

  let values = [];

  const type = "type" in jsonSchema ? jsonSchema.type : "any";

  if (key) {
    values.push({ key, returnSchemaPath, requestReturnValuePath, type });
  }

  const properties =
    jsonSchema.type === "array"
      ? //@ts-ignore
        jsonSchema.items?.properties
      : jsonSchema.type === "object"
      ? jsonSchema.properties
      : {};

  const newReturnSchemaPath =
    jsonSchema.type === "array"
      ? [...returnSchemaPath, "items", "properties"]
      : [...returnSchemaPath, "properties"];

  if (jsonSchema.type !== "array") {
    for (const [key, jsonSchemaValue] of Object.entries(properties ?? {})) {
      values = [
        ...values,
        ...recursivelyAppendAvailableParametersFromGlobalVarJSONSchema({
          key,
          jsonSchema: jsonSchemaValue as JSONSchema,
          returnSchemaPath: [...newReturnSchemaPath, key],
          requestReturnValuePath: [...requestReturnValuePath, key],
        }),
      ];
    }
  }

  return values;
};

// Filter
const appendAvailableParametersForStepToResultArray = (
  blueprint: Blueprint,
  step: BlueprintStep,
  parameterType: string,
  parameterKey: string,
  resultArray: Array<BlueprintAvailableParameter>,
  originStepType: string
): void => {
  const getAvailableParamSchemaForStepAndTraversalPath = (
    step: BlueprintStep,
    traversalPath: TraversalPath = [],
    includePathAndStatus: boolean = true
  ): BlueprintParameterSchemaValue => {
    const getParamSchema = () => {
      // if (!step) {
      //   return undefined;
      // }
      if (
        !Object.values(BlueprintStepType).includes(step.template.step_type as BlueprintStepType)
      ) {
        // template is a BlueprintGenericStepTemplate so we won't match
        // it in the switch statement.
        return step.template.return_schema;
      }
      switch (step.template.step_type) {
        case BlueprintStepType.APIRequest:
        case BlueprintStepType.CreateOrUpdate:
        case BlueprintStepType.UpdateByModelID:
        case BlueprintStepType.InitializeWrittenCommonModel:
        case BlueprintStepType.SetRemoteDeleted:
        case BlueprintStepType.GetOrCreateByRemoteId:
        case BlueprintStepType.GetById:
        case BlueprintStepType.GetRelationById:
        case BlueprintStepType.CustomFunction:
        case BlueprintStepType.APIRequestLoop:
        case BlueprintStepType.TraverseTree:
        case BlueprintStepType.DateRangeLoop:
        case BlueprintStepType.Switch:
        case BlueprintStepType.IfElse:
        case BlueprintStepType.ParseFromRemoteData:
        case BlueprintStepType.GetLinkedAccountFields:
        case BlueprintStepType.UpdateLinkedAccount:
        case BlueprintStepType.CommonModelLoop:
        case BlueprintStepType.RunBlueprint:
        case BlueprintStepType.FileToUrl:
        case BlueprintStepType.RaiseException:
        case BlueprintStepType.FileUrlToFile:
        case BlueprintStepType.ConcurrentRequestLoop:
        case BlueprintStepType.AddAvailableCustomField:
        case BlueprintStepType.FetchCustomFieldMapping:
        case BlueprintStepType.MetaAccessParentBPParamNames:
        case BlueprintStepType.AddValidationProblem:
        case BlueprintStepType.WhileLoop:
        case BlueprintStepType.GetModifiedSinceTimestampValue:
        case BlueprintStepType.CreateOAuth1Signature:
        case BlueprintStepType.CallFunctionalBP:
        case BlueprintStepType.MergeObjects:
        case BlueprintStepType.ManyToManyOverride:
        case BlueprintStepType.AddToArray:
        case BlueprintStepType.AddToDictionary:
        case BlueprintStepType.APIRequestLive:
          return step.template.return_schema;
        case BlueprintStepType.DataTransform:
          const procedureArray = ((step as BlueprintDataTransformStep).parameter_values
            .procedures as BlueprintParameterValueProcedureArray).procedure_array;
          return generateReturnSchemaFromProcedureArray(procedureArray);
        case BlueprintStepType.GetFirstInList:
        case BlueprintStepType.ArrayLoop:
          if (step.parameter_values.array == null) {
            return undefined;
          }

          //@ts-ignore
          const { step_id, value_type, return_schema_path } = step.parameter_values.array;
          if (step_id != null) {
            const returnValueStep = getStepForStepID(blueprint, step_id) as BlueprintStep;
            // Handle when path is stale
            if (returnValueStep == null) {
              return undefined;
            }
            return getAvailableParamSchemaForStepAndTraversalPath(
              returnValueStep,
              return_schema_path,
              includePathAndStatus
            );
          } else if (
            value_type === "GLOBAL_VARIABLE" &&
            blueprint.scraper &&
            return_schema_path &&
            return_schema_path[0] === "scraper"
          ) {
            const [_, ...path] = return_schema_path;
            return immutable.get(
              getReturnSchemaForScraper(blueprint.scraper),
              path
            ) as JSONSchemaValue;
          } else if (value_type === "GLOBAL_VARIABLE" && return_schema_path) {
            return immutable.get(blueprint.global_var_json_schema, return_schema_path);
          } else {
            return getEmptyArrayJSONSchema();
          }
        case BlueprintStepType.SetVariable:
        case BlueprintStepType.Assert:
        case BlueprintStepType.AddParamToEndpoint:
          return undefined;
      }
    };

    if (
      ([
        BlueprintStepType.APIRequest,
        BlueprintStepType.APIRequestLoop,
        BlueprintStepType.ConcurrentRequestLoop,
        BlueprintStepType.APIRequestLive,
      ] as Array<BlueprintStepType | string>).includes(step?.template?.step_type) &&
      includePathAndStatus
    ) {
      resultArray.push({
        labelKey: convertTraversalPathToDotNotation([step.id, "path"]),
        parameterValue: {
          step_id: step.id + "remote_data_path",
          return_schema_path: [],
          request_return_value_path: [],
          value_type: BlueprintParameterValueType.returnValue,
        },
        extraData: {
          title: "path",
          description: "Path of endpoint.",
          valueType: "string",
          deprecated: false,
        },
      });

      resultArray.push({
        labelKey: convertTraversalPathToDotNotation([step.id, "status_code"]),
        parameterValue: {
          step_id: step.id + "_status_code",
          return_schema_path: [],
          request_return_value_path: [],
          value_type: BlueprintParameterValueType.returnValue,
        },
        extraData: {
          title: "response code",
          description: "Response Code returned.",
          valueType: "string",
          deprecated: false,
        },
      });

      resultArray.push({
        labelKey: convertTraversalPathToDotNotation([step.id, "response_headers"]),
        parameterValue: {
          step_id: step.id + "_response_headers",
          return_schema_path: [],
          request_return_value_path: [],
          value_type: BlueprintParameterValueType.returnValue,
        },
        extraData: {
          title: "response headers",
          description: "Response Headers returned.",
          valueType: "any",
          deprecated: false,
        },
      });
    }

    return immutable.get(getParamSchema(), traversalPath);
  };

  const isAddingArrayLoopItem = isStepTypeALoop(step.template.step_type);

  const isAddingFirstInListItem = BlueprintStepType.GetFirstInList === step.template.step_type;

  const rootSchema = getAvailableParamSchemaForStepAndTraversalPath(
    step,
    [],
    parameterType !== "uuid"
  );

  if (isAddingArrayLoopItem || isAddingFirstInListItem) {
    recursivelyAppendParameters(
      resultArray,
      parameterType,
      step.id,
      rootSchema?.items ?? rootSchema,
      ["items"],
      isAddingArrayLoopItem ? ["item"] : [],
      step.id
    );
  } else {
    if (returnsCommonModelInstance(step.template.step_type)) {
      // exclude base common model
      const properties =
        rootSchema.type === "object" || rootSchema.type === undefined ? rootSchema.properties : {};
      for (const [key, jsonSchemaValue] of Object.entries(properties ?? {})) {
        recursivelyAppendParameters(
          resultArray,
          parameterType,
          step.id,
          //@ts-ignore
          jsonSchemaValue,
          ["properties", key],
          [key],
          key
        );
      }
    } else {
      recursivelyAppendParameters(resultArray, parameterType, step.id, rootSchema, [], [], step.id);
    }
  }

  if (parameterType === "uuid") {
    return;
  }

  if (step.template.step_type === BlueprintStepType.SetVariable) {
    const key = (step.parameter_values.key as BlueprintParameterValueConstant)?.constant;
    if (key) {
      if (parameterKey !== "key") {
        resultArray.push({
          labelKey: key,
          parameterValue: {
            key,
            value_type: BlueprintParameterValueType.variable,
          },
          extraData: {
            title: key,
            description: `Variable set with key ${key}.`,
            valueType: "any",
            deprecated: false,
          },
        });
      }

      // In the set variable type, we don't want to set the variable as the VALUE of the variable, we want to use the name of the variable itself
      // This option type of using the variable name as a constant will only exist for the set variable step
      if (originStepType === BlueprintStepType.SetVariable) {
        resultArray.push({
          labelKey: key,
          parameterValue: { value_type: BlueprintParameterValueType.constant, constant: key },
          extraData: {
            title: key,
            description: `Constant set with key ${key}.`,
            valueType: "any",
            deprecated: false,
          },
        });
      }
    }
  }

  if (step.template.step_type === BlueprintStepType.ArrayLoop) {
    resultArray.push({
      labelKey: convertTraversalPathToDotNotation([step.id, "item_index"]),
      parameterValue: {
        step_id: step.id,
        return_schema_path: ["properties", "item_index"],
        request_return_value_path: ["item_index"],
        value_type: BlueprintParameterValueType.returnValue,
      },
      extraData: {
        title: "Item Index",
        description: "Index of the current item",
        valueType: "number",
        deprecated: false,
      },
    });
  }

  if (step.template.step_type === BlueprintStepType.BatchAPIRespnse) {
    resultArray.push({
      labelKey: convertTraversalPathToDotNotation([step.id, "batch_object_list"]),
      parameterValue: {
        step_id: step.id,
        return_schema_path: ["properties", "batch_object_list"],
        request_return_value_path: ["batch_object_list"],
        value_type: BlueprintParameterValueType.returnValue,
      },
      extraData: {
        title: "Batch Object List",
        description: "List of objects in current batch.",
        valueType: "array",
        deprecated: false,
      },
    });
    resultArray.push({
      labelKey: convertTraversalPathToDotNotation([step.id, "batch_index"]),
      parameterValue: {
        step_id: step.id,
        return_schema_path: ["properties", "batch_index"],
        request_return_value_path: ["batch_index"],
        value_type: BlueprintParameterValueType.returnValue,
      },
      extraData: {
        title: "Batch Index",
        description: "Index of current batch.",
        valueType: "number",
        deprecated: false,
      },
    });
  }
};

const removeObsoleteParametersForStepToResultArray = (
  resultArray: Array<BlueprintAvailableParameter>,
  step: BlueprintStep
) => {
  switch (step.template.step_type) {
    case BlueprintStepType.CustomFunction:
      resultArray = resultArray.filter((result) => result.labelKey != step.id);
  }
  return resultArray;
};

/**
 * Used for using return value params as references to the fields themselves (rather than their values).
 */
export const getAvailableFieldLocationsForSchema = (
  stepID: string | undefined,
  jsonSchema: BlueprintParameterSchemaValue,
  resultArray: Array<BlueprintAvailableParameter>
): void => {
  const recursivelyAppendFieldLocationParams = (
    jsonSchema: BlueprintParameterSchemaValue | null,
    traversalPath: Array<string>,
    pathForDisplay: Array<string>,
    key: string
  ) => {
    if (!jsonSchema) {
      return;
    }

    if (stepID) {
      resultArray.push({
        labelKey: convertTraversalPathToDotNotation([stepID, ...pathForDisplay]),
        parameterValue: {
          step_id: stepID,
          return_schema_path: traversalPath,
          request_return_value_path: pathForDisplay,
          value_type: BlueprintParameterValueType.returnValue,
        },
        extraData: {
          title: key,
          description: "",
          valueType: jsonSchema.type,
          deprecated: !!jsonSchema.deprecated,
        },
      });
    } else if (pathForDisplay.length > 0) {
      resultArray.push({
        labelKey: `global.${convertTraversalPathToDotNotation(pathForDisplay)}`,
        parameterValue: {
          key,
          return_schema_path: traversalPath,
          request_return_value_path: pathForDisplay,
          value_type: BlueprintParameterValueType.globalVariable,
        },
        extraData: {
          title: `global.${convertTraversalPathToDotNotation(pathForDisplay)}`,
          description: "",
          valueType: jsonSchema.type,
          deprecated: !!jsonSchema.deprecated,
        },
      });
    }

    const properties =
      jsonSchema.type === "array"
        ? //@ts-ignore
          jsonSchema.items?.properties
        : jsonSchema.type === "object" || jsonSchema.type === undefined
        ? jsonSchema.properties
        : {};
    const newTraversalPath =
      jsonSchema.type === "array"
        ? [...traversalPath, "items", "properties"]
        : [...traversalPath, "properties"];

    for (const [key, jsonSchemaValue] of Object.entries(properties ?? {})) {
      recursivelyAppendFieldLocationParams(
        jsonSchemaValue,
        [...newTraversalPath, key],
        [...pathForDisplay, key],
        key
      );
    }
  };

  recursivelyAppendFieldLocationParams(jsonSchema, [], [], "");
};

const getAvailableFieldReferences = (
  blueprint: Blueprint,
  step: BlueprintStep
): Array<BlueprintAvailableParameter> => {
  let resultArray: Array<BlueprintAvailableParameter> = [];

  if (
    ([
      BlueprintStepType.MetaAddLinkedAccountParam,
      BlueprintStepType.MetaAddEnumChoiceToField,
      BlueprintStepType.MetaAccessParentBPParamNames,
    ] as Array<BlueprintStepType | string>).includes(step.template.step_type)
  ) {
    const isLinkedAccountParamStep =
      step.template.step_type === BlueprintStepType.MetaAddLinkedAccountParam;

    const allFieldReferenceSteps = getAllStepsForBlueprint(blueprint).filter(
      (step) => step.template.step_type === BlueprintStepType.MetaAccessParentBPParamNames
    );

    allFieldReferenceSteps.forEach((step) =>
      getAvailableFieldLocationsForSchema(step.id, step.template.return_schema, resultArray)
    );

    if (isLinkedAccountParamStep) {
      resultArray = resultArray.filter((availableParam) => {
        const return_schema_path = (availableParam.parameterValue as BlueprintParameterValueReturnValue)
          ?.return_schema_path;
        return return_schema_path[return_schema_path.length - 1] === "linked_account_params";
      });
    }
  } else if (
    step.template.step_type === BlueprintStepType.AddValidationProblem &&
    blueprint.parameter_schema
  ) {
    getAvailableFieldLocationsForSchema(undefined, blueprint.parameter_schema, resultArray);
  }

  return resultArray;
};

export const getConstantParametersForChoices = (
  choices: Array<string>,
  choiceNames?: Array<string>
) => {
  let resultArray: Array<BlueprintAvailableParameter> = [];
  for (let i = 0; i < choices.length; i++) {
    const choice = choices[i];
    let description = "Preset enum option for this field.";
    if (choiceNames && choiceNames.length > 0 && choiceNames[i]) {
      description = choiceNames[i];
    }
    resultArray.push({
      labelKey: choice,
      parameterValue: { value_type: BlueprintParameterValueType.constant, constant: choice },
      extraData: {
        title: choice,
        description,
        valueType: "string",
        deprecated: false,
      },
    });
  }
  return resultArray;
};

// 1. Get all steps.
// 2. Determine which steps have parameters available to the current step.
// 3. For each available step, determine available parameters based on the available step type and parameter type requested.
// 4. Remove obsolete parameters
// 5. Tack on None parameters and Constant paramets.
type Props = {
  blueprint: Blueprint;
  step: BlueprintStep;
  parameterType: string;
  currentParameterValue: BlueprintParameterValue | null;
  procedureIndex?: number;
  shouldIncludeProcedureParameters?: boolean;
  parameterKey?: string;
};

export const getParametersAvailableToStepForParameterType = ({
  blueprint,
  step,
  parameterType,
  currentParameterValue,
  procedureIndex,
  shouldIncludeProcedureParameters,
  parameterKey,
}: Props): Array<BlueprintAvailableParameter> => {
  let resultArray: Array<BlueprintAvailableParameter> = [];

  if (parameterType === "field_reference") {
    return getAvailableFieldReferences(blueprint, step);
  }

  const stepTraversalPath = getStepTraversalPathForStepID(blueprint, step.id);

  if (!stepTraversalPath) {
    return [];
  }
  stepTraversalPath.shift();

  // 1. Get all steps.
  const allSteps = getAllStepsForBlueprint(blueprint);

  const originStepType = step.template.step_type;

  allSteps.forEach((stepToCheckForParameters) => {
    if (stepToCheckForParameters.id === step.id) {
      return;
    }

    // 2. Determine which steps have parameters available to the current step.
    if (
      areParametersForBlueprintStepAvailableToCurrentStep(
        blueprint,
        [...stepTraversalPath],
        stepToCheckForParameters
      )
    ) {
      // 3. For each available step, determine available parameters based on the available step type and parameter type requested.
      appendAvailableParametersForStepToResultArray(
        blueprint,
        stepToCheckForParameters,
        parameterType,
        parameterKey ?? "",
        resultArray,
        originStepType
      );
    }

    // 4. Remove obsolete parameters
    resultArray = removeObsoleteParametersForStepToResultArray(
      resultArray,
      stepToCheckForParameters
    );
  });

  // 5. Tack on None parameters and Constant paramets.

  // Add None Step
  resultArray.push({
    labelKey: "None",
    parameterValue: { value_type: BlueprintParameterValueType.none },
    extraData: {
      title: "None",
      description: "Mark the absence of a value for this field.",
      valueType: "any",
      deprecated: false,
    },
  });

  const commonModelGlobalVarParameterValues: BlueprintAvailableParameter[] = recursivelyAppendAvailableParametersFromGlobalVarJSONSchema(
    {
      jsonSchema: blueprint.global_var_json_schema,
      returnSchemaPath: [],
      requestReturnValuePath: [],
    }
  ).map((param) => ({
    labelKey: `global.${convertTraversalPathToDotNotation(param.requestReturnValuePath)}`,
    parameterValue: {
      value_type: BlueprintParameterValueType.globalVariable,
      return_schema_path: param.returnSchemaPath,
      request_return_value_path: param.requestReturnValuePath,
      required: false,
    },
    extraData: {
      title: `global.${convertTraversalPathToDotNotation(param.requestReturnValuePath)}`,
      description: "global var",
      valueType: param.type ?? "any",
      deprecated: false,
    },
  }));

  const functionalBlueprintParameterValues: BlueprintAvailableParameter[] = recursivelyAppendAvailableParametersFromGlobalVarJSONSchema(
    {
      jsonSchema: blueprint.parameter_schema,
      returnSchemaPath: [],
      requestReturnValuePath: [],
    }
  ).map((param) => ({
    labelKey: `parameters.${convertTraversalPathToDotNotation(param.requestReturnValuePath)}`,
    parameterValue: {
      value_type: BlueprintParameterValueType.inputParameter,
      return_schema_path: param.returnSchemaPath,
      request_return_value_path: param.requestReturnValuePath,
      required: false,
    },
    extraData: {
      title: `parameters.${convertTraversalPathToDotNotation(param.requestReturnValuePath)}`,
      description: "Parameter",
      valueType: param.type ?? "any",
      deprecated: false,
    },
  }));

  resultArray = [
    ...resultArray,
    ...commonModelGlobalVarParameterValues,
    ...functionalBlueprintParameterValues,
  ];

  if (parameterType === "uuid") {
    const isUUID = (value: BlueprintAvailableParameter) => {
      return [value.extraData.format, value.extraData.valueType].includes("uuid");
    };
    return [
      ...resultArray.filter((value) => isUUID(value)),
      ...resultArray.filter((value) => !isUUID(value)),
    ];
  }

  // Add scraper values

  if (blueprint.scraper) {
    recursivelyAppendParameters(
      resultArray,
      "any",
      "Scraper",
      getReturnSchemaForScraper(blueprint.scraper),
      ["scraper"],
      ["scraper"],
      "scraper",
      true
    );
  }

  // Add constant value if it is the current step.
  if (currentParameterValue?.value_type === BlueprintParameterValueType.constant) {
    resultArray.push({
      labelKey: currentParameterValue.constant,
      parameterValue: currentParameterValue,
      extraData: {
        title: currentParameterValue.constant,
        description: "A constant value.",
        valueType: parameterType,
        deprecated: false,
      },
    });
  }

  // Add procedure return values from previous indices from same step if available
  if (procedureIndex != null) {
    appendProcedureParameters(
      step,
      parameterType,
      resultArray,
      procedureIndex,
      shouldIncludeProcedureParameters
    );
  }

  return resultArray;
};

export const isParameterCurrentlySelectedParameterValue = (
  availableParameterValue: BlueprintParameterValue,
  currentParameterValue: BlueprintParameterValue
): boolean => {
  switch (currentParameterValue.value_type) {
    case BlueprintParameterValueType.returnValue:
      return (
        availableParameterValue.value_type === BlueprintParameterValueType.returnValue &&
        currentParameterValue.step_id === availableParameterValue.step_id &&
        JSON.stringify(currentParameterValue.return_schema_path) ===
          JSON.stringify(availableParameterValue.return_schema_path) &&
        JSON.stringify(currentParameterValue.request_return_value_path) ===
          JSON.stringify(availableParameterValue.request_return_value_path)
      );
    case BlueprintParameterValueType.inputParameter:
      return (
        availableParameterValue.value_type === BlueprintParameterValueType.inputParameter &&
        JSON.stringify(currentParameterValue.return_schema_path) ===
          JSON.stringify(availableParameterValue.return_schema_path) &&
        JSON.stringify(currentParameterValue.request_return_value_path) ===
          JSON.stringify(availableParameterValue.request_return_value_path)
      );
    case BlueprintParameterValueType.constant:
      return (
        availableParameterValue.value_type === BlueprintParameterValueType.constant &&
        currentParameterValue.constant === availableParameterValue.constant
      );
    case BlueprintParameterValueType.nestedParameterValues:
      return (
        availableParameterValue.value_type === BlueprintParameterValueType.nestedParameterValues &&
        currentParameterValue.nested_parameter_values ===
          availableParameterValue.nested_parameter_values
      );
    case BlueprintParameterValueType.none:
      return availableParameterValue.value_type === BlueprintParameterValueType.none;
    case BlueprintParameterValueType.procedureArray:
    case BlueprintParameterValueType.statementArray:
      return false;
    case BlueprintParameterValueType.globalVariable:
      return (
        availableParameterValue.value_type === BlueprintParameterValueType.globalVariable &&
        currentParameterValue.key === availableParameterValue.key &&
        ((!currentParameterValue.request_return_value_path &&
          !availableParameterValue.request_return_value_path) ||
          JSON.stringify(currentParameterValue.request_return_value_path) ===
            JSON.stringify(availableParameterValue.request_return_value_path))
      );
    case BlueprintParameterValueType.variable:
      return (
        availableParameterValue.value_type === BlueprintParameterValueType.variable &&
        currentParameterValue.key === availableParameterValue.key
      );
    case BlueprintParameterValueType.customObject:
      // We don't want these to show up in typeahead.
      return false;
    case BlueprintParameterValueType.customArray:
      // We don't want these to show up in typeahead.
      return false;
  }
};

export const getParentStepForSubstep = (
  blueprint: Blueprint | ScraperVersion,
  step: BlueprintStep | ScraperStep
): null | BlueprintStep | ScraperStep => {
  const stepTraversalPath = getStepTraversalPathForStepID(blueprint, step.id);
  const parentTraversalPath = stepTraversalPath?.slice(0, -3) ?? [];
  // root level, not a substep - or step not found
  if (isEmpty(parentTraversalPath)) {
    return null;
  } else {
    return getForTraversalPath(blueprint, parentTraversalPath) as BlueprintStep;
  }
};

export const getPathKeyForStep = (
  parentStep: BlueprintStep | ScraperStep,
  step: BlueprintStep | ScraperStep
): string => {
  let pathKey = "";

  const stepTraversalHelper = (
    parentStep: BlueprintStep | ScraperStep,
    step: BlueprintStep | ScraperStep
  ) => {
    if (parentStep.paths != null) {
      for (const [currentPathKey, substeps] of Object.entries(parentStep.paths)) {
        pathKey = currentPathKey;
        if (getStepForStepIDInPath(substeps, step.id) != null) {
          return;
        }
      }
    }
  };

  stepTraversalHelper(parentStep, step);
  return pathKey;
};

export const getNumberOfPathsForStep = (step: BlueprintStep | ScraperStep): number => {
  let numberOfPaths = 0;

  const stepTraversalHelper = (step: BlueprintStep | ScraperStep) => {
    if (step.paths != null) {
      for (const [_] of Object.entries(step.paths)) {
        numberOfPaths += 1;
      }
    }
  };

  stepTraversalHelper(step);
  return numberOfPaths;
};

export const getPredecessorStepForStep = (
  blueprint: Blueprint | ScraperVersion,
  step: BlueprintStep | ScraperStep
): null | BlueprintStep | ScraperStep => {
  const stepTraversalPath = getStepTraversalPathForStepID(blueprint, step.id);
  if (!stepTraversalPath) {
    return null;
  }
  const childIndex = stepTraversalPath[stepTraversalPath.length - 1] as number;
  // if first in array, no predecessor step
  if (childIndex === 0) {
    return null;
  } else {
    return getForTraversalPath(blueprint, [
      ...stepTraversalPath?.slice(0, -1),
      childIndex - 1,
    ]) as BlueprintStep;
  }
};

export const canStepHaveMoreThanTwoPaths = (step: BlueprintStep | ScraperStep): boolean =>
  "template" in step
    ? ([
        BlueprintStepType.Switch,
        BlueprintStepType.FileToUrl,
        BlueprintStepType.FileUrlToFile,
      ] as Array<BlueprintStepType | string>).includes(step.template.step_type)
    : [
        ScraperStepType.CONDITIONAL_ACTION,
        ScraperStepType.GET_USER_INPUT,
        ScraperStepType.ARRAY_LOOP,
        ScraperStepType.QUERY_SELECTOR_LOOP,
        ScraperStepType.EXPECT_DOWNLOAD_CSV,
      ].includes(step.step_type);

export const isStepTypeAPIRequest = (step: BlueprintStep): boolean => {
  if ("template" in step) {
    return ([
      BlueprintStepType.APIRequestLoop,
      BlueprintStepType.APIRequest,
      BlueprintStepType.BatchAPIRespnse,
    ] as Array<BlueprintStepType | string>).includes(step.template.step_type);
  }
  return false;
};

export const isStepTypeFunctionalBP = (step: BlueprintStep): boolean => {
  if ("template" in step) {
    return ([BlueprintStepType.CallFunctionalBP] as Array<BlueprintStepType | string>).includes(
      step.template.step_type
    );
  }
  return false;
};

export const isStepTypeWithPaths = (step: BlueprintStep | ScraperStep): boolean =>
  "template" in step
    ? ([
        BlueprintStepType.APIRequestLoop,
        BlueprintStepType.ArrayLoop,
        BlueprintStepType.WhileLoop,
        BlueprintStepType.DateRangeLoop,
        BlueprintStepType.CommonModelLoop,
        BlueprintStepType.IfElse,
        BlueprintStepType.Switch,
        BlueprintStepType.BatchAPIRespnse,
        BlueprintStepType.TraverseTree,
      ] as Array<BlueprintStepType | string>).includes(step.template.step_type)
    : [
        ScraperStepType.CONDITIONAL_ACTION,
        ScraperStepType.GET_USER_INPUT,
        ScraperStepType.ARRAY_LOOP,
        ScraperStepType.QUERY_SELECTOR_LOOP,
        ScraperStepType.IF_ELSE,
        ScraperStepType.EXPECT_DOWNLOAD_CSV,
      ].includes(step.step_type);

export const getOperationNameFromOperationType = (operationType: BlueprintOperationType) => {
  switch (operationType) {
    case BlueprintOperationType.FETCH:
      return "Fetch";
    case BlueprintOperationType.CREATE:
      return "Create";
    case BlueprintOperationType.UPSERT:
      return "Upsert";
    case BlueprintOperationType.DELETE:
      return "Delete";
    case BlueprintOperationType.EDIT:
      return "Edit";
    case BlueprintOperationType.VALIDATE:
      return "Validate";
    case BlueprintOperationType.GET_AVAILABLE_CUSTOM_FIELDS:
      return "Custom Fields";
    case BlueprintOperationType.META:
      return "Meta";
    case BlueprintOperationType.FUNCTIONAL:
      return "Functional";
    case BlueprintOperationType.ENDPOINT_MODIFICATION:
      return "Endpoint Modification";
    case BlueprintOperationType.WEBHOOK_SETUP:
      return "Webhook Setup";
    case BlueprintOperationType.CUSTOM_OBJECT_CLASS_GENERATION:
      return "Custom Object Class Generation";
    case BlueprintOperationType.FETCH_FILTER_OPTIONS:
      return "Fetch Filter Options";
    case BlueprintOperationType.PROXY:
      return "Proxy";
    case BlueprintOperationType.LIVE_REQUEST:
      return "Live Request";
    case BlueprintOperationType.ACCOUNT_DELETION:
      return "Account Deletion";
  }
};

export const getTriggerTypeNameFromTriggerType = (triggerType: BlueprintTriggerType) => {
  switch (triggerType) {
    case BlueprintTriggerType.REPORT_RECEIVED:
      return "Report Received";
    case BlueprintTriggerType.PERIODIC_TASK:
      return "Periodic Task";
    case BlueprintTriggerType.API_REQUEST:
      return "API Request";
    case BlueprintTriggerType.INITIAL_LINK:
      return "Initial Link";
    case BlueprintTriggerType.VALIDATE_AUTH:
      return "API Request";
    case BlueprintTriggerType.FORCE_SYNC:
      return "Force Sync";
    case BlueprintTriggerType.DELETION_DETECTION:
      return "Deletion Detection";
  }
  return firstLetterUpperCase(triggerType);
};

export enum ModifySwitchOptionType {
  ADD,
  DELETE,
}

export enum PARAMETER_TYPES {
  ANY = "any",
  ARRAY = "array",
  BOOLEAN = "boolean",
  FIELD_REFERENCE = "field_reference",
  NUMBER = "number",
  OBJECT = "object",
  STRING = "string",
  URL = "url",
  UUID = "uuid",
}

export const modifySwitchOption = (
  blueprint: Blueprint,
  step: BlueprintSwitchStep,
  optionKey: string,
  modificationType: ModifySwitchOptionType
): { blueprint: Blueprint; step: BlueprintStep } => {
  const { constant: options } = step.parameter_values.options as BlueprintParameterValueConstant;
  let newOptions = [...options];
  const newStep = Object.assign({}, step);

  switch (modificationType) {
    case ModifySwitchOptionType.ADD:
      newOptions = [...newOptions, optionKey];
      if (newStep.paths) {
        newStep.paths[optionKey] = [];
      }
      break;
    case ModifySwitchOptionType.DELETE:
      const deletedOptionIndex = options.findIndex((v: string) => v === optionKey);
      newOptions = [...newOptions].splice(deletedOptionIndex, 1);
      if (newStep.paths) {
        delete newStep.paths["optionKey"];
      }
      break;
  }

  if (newStep.parameter_values.options.value_type === BlueprintParameterValueType.constant) {
    newStep.parameter_values.options.constant = newOptions;
  }

  return updateStep(blueprint, newStep);
};

// Triggers

export const getTriggerFrequencyString = (
  intervalValue: number | null,
  intervalUnit: BlueprintTriggerIntervalUnit
) =>
  intervalValue === 0
    ? "None"
    : "Every " +
      (intervalValue === 1 ? intervalUnit.slice(0, -1) : intervalValue + " " + intervalUnit);

// Parameter Value Custom Functions

// TODO - doesn't actually support multiple levels of nesting. Unlikely we'll need it, but keep that in mind
const getParameterValueFromCustomFunctionSchema = (
  currentParameterValue: BlueprintParameterValue | null,
  customFunction: BlueprintParameterValueCustomFunction | null
) => {
  switch (customFunction?.parameter_type?.type) {
    case "object":
      return {
        nested_parameter_values: {
          ...Object.fromEntries(
            Object.keys(customFunction.parameter_type?.properties).map((key) => [key, undefined])
          ),
        },
        value_type: BlueprintParameterValueType.nestedParameterValues,
      };
    default:
      return currentParameterValue?.value_type !== BlueprintParameterValueType.nestedParameterValues
        ? currentParameterValue
        : null;
  }
};

const getParameterValueCustomFunctionSchemaForType = (
  customFunctionType: BlueprintParameterValueCustomFunctionType | "None" | null
): BlueprintParameterValueCustomFunction | null => {
  if (customFunctionType === null) {
    return null;
  }

  switch (customFunctionType) {
    case BlueprintParameterValueCustomFunctionType.CONVERT_TO_ARRAY:
      return {
        custom_function_type: customFunctionType,
        parameter_type: { type: "any" },
      };
    case BlueprintParameterValueCustomFunctionType.CONCATENTATE_STRINGS:
      return {
        custom_function_type: customFunctionType,
        parameter_type: {
          type: "object",
          properties: {
            value1: { type: "any" },
            value2: { type: "any" },
          },
        },
      };
    case BlueprintParameterValueCustomFunctionType.GET_FIRST_VALUE_FROM_OBJECT_LIST:
      return {
        custom_function_type: customFunctionType,
        parameter_type: {
          type: "object",
          properties: {
            object_array: { type: "array", items: { type: "any" } },
            filter_name: { type: "any" },
            filter_value: { type: "any" },
            value: { type: "any" },
          },
        },
      };
    case BlueprintParameterValueCustomFunctionType.RETRIEVE_DICT_VALUE_FROM_KEY:
      return {
        custom_function_type: customFunctionType,
        parameter_type: {
          type: "object",
          properties: {
            lookup_dict: { type: "object", properties: {} },
            key: { type: "any" },
          },
        },
      };
    case "None":
      return null;
  }
};

export const getUpdatedStepParameterValueForCustomFunctionChange = (
  currentParameterValue: BlueprintParameterValue | null,
  customFunctionType: BlueprintParameterValueCustomFunctionType | "None" | null
) => {
  const customFunction = getParameterValueCustomFunctionSchemaForType(customFunctionType);
  const choiceMapping =
    currentParameterValue && "choice_mapping" in currentParameterValue
      ? currentParameterValue.choice_mapping
      : undefined;

  return {
    ...getParameterValueFromCustomFunctionSchema(currentParameterValue, customFunction),
    custom_function: customFunction,
    choice_mapping: choiceMapping,
  };
};

export const isBlueprintWriteOperation = (operationType: BlueprintOperationType) =>
  blueprintWriteOperations.includes(operationType);

export const isBlueprintProxyOperation = (operationType: BlueprintOperationType) =>
  blueprintProxyOperations.includes(operationType);

export const isUpdateExistingModelBlueprintOperation = (operationType: BlueprintOperationType) =>
  blueprintUpdateExistingModelOperations.includes(operationType);

export const isBlueprintStep = (
  step?: BlueprintStepOrGhostStepOrTriggerOrScraper
): step is BlueprintStep => {
  if (step === undefined) {
    return false;
  }
  if (step.template && step.template === GHOST_STEP_TEMPLATE_VALUE) {
    return false;
  }
  return true;
};

export const initializeInputParamsForProxy = (remoteId: string) => {
  return {
    model: {
      remote_id: remoteId,
    },
  };
};

export const initializeInputParams = (jsonSchema: JSONObjectSchema, shouldRecurse: boolean) => {
  const getSampleForVal = (val: JSONSchemaValue, shouldRecurse: boolean): unknown => {
    if (val.type === "object") {
      return shouldRecurse ? jsonTraversal(val as JSONObjectSchema) : null;
    } else if (val.type === "array") {
      return [];
    } else {
      return null;
    }
  };

  const jsonTraversal = (schema: JSONObjectSchema, isTopLevel = false) =>
    Object.fromEntries(
      Object.entries(schema.properties).map(([key, val]) => {
        return [key, getSampleForVal(val, shouldRecurse || isTopLevel)];
      })
    );

  return jsonTraversal(jsonSchema, true);
};

type ExpandStepsButtonToggleProps = {
  onClick: () => void;
};

export const ExpandStepsButton = ({ onClick }: ExpandStepsButtonToggleProps) => {
  return (
    <div className="ml-3 text-left align-self-center overflow-hidden">
      <Button size="sm" className="w-100" variant="outline-primary" onClick={onClick}>
        <div className="ml-1.5">
          Expand
          <i className={`fe fe-arrow-right pr-1`} />
        </div>
      </Button>
    </div>
  );
};

export const getStaleParameterValueKeysForStep = (
  blueprint: Blueprint,
  step: BlueprintStep
): Set<string> => {
  const stepTemplate = step?.template;

  const result = new Set<string>();

  if (stepTemplate) {
    Object.entries(stepTemplate.parameter_schema?.properties ?? {})
      .sort()
      .map(([key, parameter]) => {
        const valueKey = key;

        const paramReturnSchema = stepTemplate.return_schema;
        const paramProps = paramReturnSchema.properties
          ? paramReturnSchema.properties[key]
          : undefined;

        function getValueType(): PARAMETER_TYPES | string | undefined {
          const paramValueType: PARAMETER_TYPES =
            paramProps?.format === PARAMETER_TYPES.UUID ? PARAMETER_TYPES.UUID : parameter.type;
          if (paramValueType) {
            switch (paramValueType) {
              case PARAMETER_TYPES.UUID:
                return `${paramProps?.relation?.model || ""} UUID`;
              case PARAMETER_TYPES.URL:
                return "URL";
              default:
                return firstLetterUpperCase(paramValueType);
            }
          }
          return undefined;
        }

        const valueType = getValueType();

        const parameterType = [valueType, parameter.type].includes(PARAMETER_TYPES.UUID)
          ? PARAMETER_TYPES.UUID
          : PARAMETER_TYPES.ANY;

        const choices =
          parameter?.enum ??
          stepTemplate.parameter_schema?.[valueKey]?.enum ??
          // @ts-ignore choices is deprecated
          stepTemplate.parameter_schema?.[valueKey]?.choices;

        const currentParameterValue = getCurrentStepParameterValue(step, valueKey);

        const availableParameters = [
          ...getConstantParametersForChoices(choices ?? []).sort(),
          ...getParametersAvailableToStepForParameterType({
            blueprint: blueprint,
            step: step,
            parameterType: parameterType,
            currentParameterValue: currentParameterValue,
            parameterKey: valueKey,
          }).sort(),
        ];

        // Empty when in the canvas for some reason
        const selectedParameters =
          currentParameterValue != null
            ? availableParameters.filter((availableParameter) =>
                isParameterCurrentlySelectedParameterValue(
                  availableParameter.parameterValue,
                  currentParameterValue
                )
              )
            : [];

        const isCurrentParameterCustomJSON = isParameterValueCustomJSON(currentParameterValue);

        const isCurrentlySelectedParameterStale =
          currentParameterValue != null &&
          selectedParameters.length === 0 &&
          !isCurrentParameterCustomJSON;

        if (isCurrentlySelectedParameterStale) {
          result.add(key);
        }
      });
  }
  return result;
};

export const calculateCoverageLevelForStep = (
  coverageForStep: BlueprintIndividualStepCoverage | undefined,
  isCheckingFunctionalBlueprint: boolean
): StepCoverageDetails => {
  if (!coverageForStep) {
    return {
      coverageLevel: null,
      coverageMessage: null,
    };
  }

  if (!coverageForStep.was_step_called) {
    return {
      coverageLevel: StepCoverageLevel.MISSING,
      coverageMessage: "All parameters missing from test coverage",
    };
  } else {
    let numOfMissingParameters = 0;
    for (const parameter in coverageForStep.parameter_value_coverage) {
      let parameterValueCoverage = coverageForStep.parameter_value_coverage[parameter];
      if (
        parameterValueCoverage?.is_parameter_used_in_blueprint &&
        !parameterValueCoverage?.was_non_null_value_used
      ) {
        if (isCheckingFunctionalBlueprint) {
          return {
            coverageLevel: StepCoverageLevel.INCOMPLETE,
            coverageMessage: "Some parameters in functional blueprint missing from test coverage",
          };
        } else {
          numOfMissingParameters += 1;
        }
      }
    }

    // Check if any missing coverage within Functional BP
    const functionalBpCoverageForStep = coverageForStep?.functional_bp_coverage?.step_coverage;
    let isFunctionalBpParameterMissing = false;
    for (const stepKey in functionalBpCoverageForStep) {
      if (
        calculateCoverageLevelForStep(functionalBpCoverageForStep[stepKey], true).coverageLevel !=
        StepCoverageLevel.COMPLETE
      ) {
        isFunctionalBpParameterMissing = true;
      }
    }

    if (numOfMissingParameters != 0) {
      return {
        coverageLevel: StepCoverageLevel.INCOMPLETE,
        coverageMessage: `${numOfMissingParameters} parameters missing from test coverage`,
      };
    } else if (isFunctionalBpParameterMissing) {
      return {
        coverageLevel: StepCoverageLevel.INCOMPLETE,
        coverageMessage: "Some parameters within functional blueprint missing from test coverage",
      };
    } else {
      return {
        coverageLevel: StepCoverageLevel.COMPLETE,
        coverageMessage: "No parameters missing from test coverage",
      };
    }
  }
};

export function viewBlueprintDiff(
  onHide: () => void,
  setBlueprintToCompare: React.Dispatch<React.SetStateAction<Blueprint | undefined>>,
  setIsShowingDiffModal: React.Dispatch<React.SetStateAction<boolean>>,
  setIsLoadingBlueprintToCompare: React.Dispatch<React.SetStateAction<boolean>>,
  blueprint: Blueprint,
  blueprintVersionId: string
) {
  onHide();
  setIsShowingDiffModal(true);
  setIsLoadingBlueprintToCompare(true);
  fetchBlueprintVersionForIntegration({
    integrationID: blueprint.integration.id,
    blueprintVersionID: blueprintVersionId,
    onSuccess: (response: BlueprintWithTrigger) => {
      const { schedule, ...blueprint } = response;
      setBlueprintToCompare(blueprint);
      setIsLoadingBlueprintToCompare(false);
    },
  });
}
