import { Typeahead } from "@merge-api/merge-javascript-shared";
import { useEffect, useState } from "react";
import { APIEndpointParameter } from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_APIEndpointParameter";
import {
  UserFacingFilterDetails,
  APIEndpointParameterMapping,
} from "../../../../../autogenerated-types/AUTOGENERATED_ExpandedPydantic_SelectiveSyncFilterSchema";
import EmptyStateWrapper from "../../../../shared/layout/EmptyStateWrapper";
import {
  APIEndpointParameterAPIResponse,
  VersionedComponentAPIActions,
  VersionedComponentAPIComponentKeys,
  VersionedComponentInfo,
  VersionedComponentsAPIRequestData,
} from "../../../../versioned-components/types";
import { makeVersionedComponentsAPIRequest } from "../../../VersionedComponentsAPIClient";
import useSelectiveSyncFilterBuilderContext from "../../context/useSelectiveSyncFilterBuilderContext";
import APIEndpointParameterChoiceRow from "./APIEndpointParameterChoiceRow";
import APIEndpointParameterForm from "./APIEndpointParameterForm";

interface Props {
  userFacingDetails: UserFacingFilterDetails;
  parameterMappingDetails: APIEndpointParameterMapping;
  existingAPIEndpointParameter: APIEndpointParameter | null | undefined;
  setOnSubmit: () => void;
  addOrUpdateAPIEndpointParameter: (
    newAPIEndpointParameter: VersionedComponentInfo<APIEndpointParameter>
  ) => void;
  setIsModalOpen: (value: boolean) => void;
}

const EditAPIEndpointParameterContainer = ({
  userFacingDetails,
  existingAPIEndpointParameter,
  setOnSubmit,
  setIsModalOpen,
  addOrUpdateAPIEndpointParameter,
}: Props) => {
  const { integrationID } = useSelectiveSyncFilterBuilderContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAPIEndpointParameterFromVersionedComponentInfo = (
    componentInfo: VersionedComponentInfo<APIEndpointParameter>
  ) => {
    return (
      componentInfo?.next_version ?? (componentInfo?.published_version as APIEndpointParameter)
    );
  };

  const [apiEndpointParameter, setApiEndpointFilter] = useState<APIEndpointParameter | null>(
    existingAPIEndpointParameter ?? null
  );
  const [availableApiEndpointFilters, setAvailableApiEndpointFilters] = useState<
    APIEndpointParameter[]
  >([]);

  useEffect(() => {
    setIsLoading(true);
    const request_body: VersionedComponentsAPIRequestData = {
      component_key: VersionedComponentAPIComponentKeys.APIEndpointParameter,
    };

    makeVersionedComponentsAPIRequest({
      integrationID,
      versioned_components_api_action:
        VersionedComponentAPIActions.GET_COMPONENTS_FOR_COMPONENT_CLASS,
      request_body,
      onResponse: (data: APIEndpointParameterAPIResponse) => {
        const apiEndpointFilters = Object.values(data).map((apiEndpointParameter) =>
          getAPIEndpointParameterFromVersionedComponentInfo(apiEndpointParameter)
        );

        setAvailableApiEndpointFilters(apiEndpointFilters);
        setIsLoading(false);
      },
    });
  }, []);

  if (isLoading) {
    return <EmptyStateWrapper isSpinner />;
  }

  return (
    <>
      <Typeahead
        className="mb-2"
        options={availableApiEndpointFilters}
        value={apiEndpointParameter?.api_endpoint_id?.name ?? ("" as any)}
        onChange={(_: any, val: any) => setApiEndpointFilter(val)}
        renderOption={(apiEndpointParameter) => (
          <APIEndpointParameterChoiceRow apiEndpointParameter={apiEndpointParameter} />
        )}
        filterOptions={(options: any, { inputValue }: any) => {
          return options.filter((option: APIEndpointParameter) => {
            const inputValueToLowerCase = inputValue.toLowerCase();
            const endpointName = (option.api_endpoint_id?.name ?? "").toLowerCase();
            const path = (option?.api_endpoint_id?.path ?? "").toLowerCase();
            return (
              endpointName.includes(inputValueToLowerCase) || path.startsWith(inputValueToLowerCase)
            );
          });
        }}
      />
      {apiEndpointParameter && (
        <APIEndpointParameterForm
          key={apiEndpointParameter?.id}
          userFacingDetails={userFacingDetails}
          apiEndpointParameter={apiEndpointParameter}
          setOnSubmit={setOnSubmit}
          isEditingExistingAPIEndpointParameter={true}
          setIsModalOpen={setIsModalOpen}
          addOrUpdateAPIEndpointParameter={addOrUpdateAPIEndpointParameter}
        />
      )}
    </>
  );
};

export default EditAPIEndpointParameterContainer;
