import { useEffect, useState } from "react";
import useBlueprintContext from "../../context/useBlueprintContext";
import ConnectorInputHeader from "./ConnectorInputHeader";
import Editor from "react-simple-code-editor";
import { isValidJSON } from "../../../../utils";
import { JSONObjectSchema } from "../../../../models/Blueprints";
import { Button, ButtonVariant } from "@merge-api/merge-javascript-shared";
import { ArrowUpRight } from "lucide-react";
import ConverterModal from "../shared/ConverterModal";
import useConvertQBXMLToJson from "../hooks/useConvertQBXMLToJson";

const { highlight, languages } = require("prismjs");

interface ParameterSchemaEditorProps {
  parameterSchema: undefined | JSONObjectSchema;
}

const ParameterSchemaEditor = ({ parameterSchema }: ParameterSchemaEditorProps) => {
  // constants
  const NOTION_QBD_PARAMER_SCHEMA_LINK =
    "https://www.notion.so/mergeapi/Constructing-parameter-schema-for-QBD-blueprints-1111da993e9780428126d25cb502ada6";

  // context
  const { setBlueprintParameterSchema } = useBlueprintContext();

  // state for parameter schema string and modal
  const [parameterSchemaStr, setParameterSchemaStr] = useState<string>(
    JSON.stringify(parameterSchema, null, 2) ?? ""
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  // hooks
  const { convertQbxmlToJson, isLoadingConversion, conversionResult } = useConvertQBXMLToJson();

  // Updates the parameter schema if and only if inputted parameter schema is valid JSON
  useEffect(() => {
    if (parameterSchemaStr) {
      try {
        const parsedSchema: JSONObjectSchema = JSON.parse(parameterSchemaStr);

        // Ensure it's valid JSON and conforms to JSONObjectSchema
        if (isValidJSON(parameterSchemaStr) && parsedSchema.type === "object") {
          setBlueprintParameterSchema(parsedSchema);
        }
      } catch (error) {
        // No need for an error, we show and tell the user that it is invalid JSON
      }
    }
  }, [parameterSchemaStr, setBlueprintParameterSchema]);

  const isParamaterSchemaValidJSON =
    !parameterSchemaStr || (parameterSchemaStr && isValidJSON(parameterSchemaStr));
  return (
    <>
      <ConnectorInputHeader
        link={NOTION_QBD_PARAMER_SCHEMA_LINK}
        title="Parameter schema"
        description="The parameter schema of the blueprint that will ingest the qbXML response, which has been converted to JSON, from the web connector"
      />
      <Editor
        highlight={(code) => highlight(code, languages.js)}
        value={parameterSchemaStr ?? ""}
        onValueChange={(code) => {
          setParameterSchemaStr(code);
        }}
        padding={10}
        className="mt-2 mb-2"
        style={{
          backgroundColor: isParamaterSchemaValidJSON ? "white" : "#FFE0E0",
          border: "1px solid #d2ddec",
          borderRadius: 8,
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          minHeight: "100px",
          overflow: "auto",
        }}
      />
      <Button
        variant={ButtonVariant.TextBlue}
        size="sm"
        onClick={() => {
          setIsModalOpen(true);
        }}
        rightIcon={<ArrowUpRight size={12} />}
      >
        Convert qbXML to JSON
      </Button>
      <ConverterModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        inputTitle="qbXML"
        outputTitle="JSON"
        placeholder="Add qbXML"
        convertFunction={(input: string) => convertQbxmlToJson({ qbxmlString: input })}
        isLoading={isLoadingConversion}
        result={conversionResult}
      />
    </>
  );
};

export default ParameterSchemaEditor;
